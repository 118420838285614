import { useEffect, useState, useContext } from "react";
import { StyleSheet } from "react-native";
import { useParams } from "react-router-dom";
import { Calendar } from "react-native-calendars";
import {
  Container,
  Paper,
  Typography,
  Box,
  Avatar,
  Grid,
  IconButton,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LoadingScreen from "./LoadingScreen";
import AgendaList from "./AgendaList";
import Moment from "moment-timezone";
import { extendMoment } from "moment-range";
import { isEmpty } from "lodash";
import { randomColor } from "./utils";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import AuthContext from "./AuthContext";
const axios = require("axios").default;
const moment = extendMoment(Moment);

const ScheduleScreen = (props) => {
  const {
    theme,
    setThemeMode,
    mode,
    updateThemeColors,
    secondaryColor,
    primaryColor,
    setErrorMessage,
  } = useContext(AuthContext);
  const [user, setUser] = useState({ name: null });
  const [times, setTimes] = useState({
    original: [],
    filtered: [],
    marked: {},
  });
  const [event, setEvent] = useState({ meetingFutureBooking: 60 });
  const [settings, setSettings] = useState({});
  const [localSettings, setLocalSettings] = useState({
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [spinner, setSpinner] = useState(false);

  var dates = {
    selectedDate: null,
    todayDate: moment(),
    startDate: moment().startOf("month").isAfter(moment())
      ? moment().startOf("month")
      : moment(),
    selectedMonth: moment(),
    startOfMonth: moment().startOf("month"),
    endOfMonth: moment().endOf("month"),
    monthRange: moment.range(
      moment().startOf("month"),
      moment().endOf("month")
    ),
    maxStartDate: moment().add(event.meetingFutureBooking, "days"),
  };
  const { username, link } = useParams();

  const getAvailableTimes = () => {
    setSpinner(true);
    const sd = dates.startDate.format("YYYY-MM-DD");
    const ed = dates.endOfMonth.format("YYYY-MM-DD");
    if (dates.startDate.isAfter(dates.maxStartDate)) {
      setSpinner(false);
      setErrorMessage(
        `Meetings can only be scheduled ${event.meetingFutureBooking} days in the future`,
        3000
      );
      return;
    }
    console.log("Fetching available times", username, sd, ed);
    axios
      .get(
        "https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/available/times",
        {
          params: {
            username: username,
            link: link,
            startDate: sd,
            endDate: ed,
            offset: moment().utcOffset(),
          },
        }
      )
      .then((res) => {
        if (res.data !== "") {
          const primaryColor = res.data.event.color || randomColor();
          const userObj = formatUser(res.data.user, res.data.settings);
          const sections = getSections(
            res.data.schedule,
            userObj,
            res.data.event
          );
          const selectedDateStr = isEmpty(dates.selectedDate)
            ? getFirstDate(sections)
            : dates.selectedDate;
          setUser(userObj);
          setEvent({ ...event, ...res.data.event });
          setSettings(res.data.settings);
          setTimesData(sections, selectedDateStr, primaryColor);
          updateThemeColors(mode, primaryColor, secondaryColor);
          setLoadingScreen(false);
          setSpinner(false);
        }
      });
  };

  const setTimesData = (sections, selectedDateStr, primaryColor) => {
    setTimes({
      original: sections,
      filtered: filterSections(sections, selectedDateStr),
      marked: getMarked(sections, selectedDateStr, primaryColor),
    });
  };

  const filterSections = (sections, selectedDate) => {
    const filteredList = sections.filter((x) => x.title === selectedDate);
    if (isEmpty(filteredList)) {
      return [{ title: selectedDate, data: [{}] }];
    }
    return filteredList;
  };

  useEffect(() => {
    moment.tz.setDefault(localSettings.timezone);
    getAvailableTimes();
  }, [localSettings]);

  const getSections = (timesList, userObj, eventObj) => {
    return timesList.map((time) => {
      const timeMoment = moment(time.day);
      var data = time.meetingTimes
        .filter((x) => x.status === "available")
        .map((meeting) => {
          const meet = moment(meeting.start);
          return {
            ...eventObj,
            username: username,
            name: userObj.name,
            picture: userObj.picture,
            title: "Available Booking",
            meetingLength: meeting.meetingLength,
            hour: meet.format("h:mma"),
            available: true,
            start: meeting.start,
          };
        });
      if (!data || !data.length) {
        data = [];
      }
      return {
        title: timeMoment.format("YYYY-MM-DD"),
        data: data,
      };
    });
  };

  const onMonthChange = (months) => {
    if (isEmpty(months)) {
      return;
    }
    console.log("Month change", months[0].dateString, times);
    const monthDate = moment(months[0].dateString).startOf("month");
    dates = {
      todayDate: moment(monthDate),
      selectedMonth: moment(monthDate),
      startDate: monthDate.isAfter(moment()) ? moment(monthDate) : moment(),
      startOfMonth: moment(monthDate).startOf("month"),
      endOfMonth: moment(monthDate).endOf("month"),
      monthRange: moment.range(
        moment(monthDate).startOf("month"),
        moment(monthDate).endOf("month")
      ),
      maxStartDate: moment().add(event.meetingFutureBooking, "days"),
    };
    getAvailableTimes();
  };

  const onDateChanged = (dateStr) => {
    console.log("Date changed", dateStr.dateString, times, dates);
    const monthDate = moment(dateStr.dateString).startOf("month");
    dates = {
      selectedDate: dateStr.dateString,
      todayDate: moment(monthDate),
      selectedMonth: moment(monthDate),
      startDate: monthDate.isAfter(moment()) ? moment(monthDate) : moment(),
      startOfMonth: moment(monthDate).startOf("month"),
      endOfMonth: moment(monthDate).endOf("month"),
      monthRange: moment.range(
        moment(monthDate).startOf("month"),
        moment(monthDate).endOf("month")
      ),
    };
    setTimesData(times.original, dates.selectedDate, primaryColor);
  };

  const formatUser = (userObj, settings) => {
    return {
      ...user,
      initials: userObj.name
        .split(" ")
        .map((n) => n[0])
        .join(""),
      ...userObj,
      ...settings,
    };
  };

  const getFirstDate = (sections) => {
    if (isEmpty(sections)) {
      return moment().format("YYYY-MM-DD");
    }
    const firstDay = sections.find((x) => {
      var found = false;
      x.data.forEach((x) => {
        if (x.available) {
          found = true;
        }
      });
      return found;
    });
    return firstDay ? firstDay.title : moment().format("YYYY-MM-DD");
  };

  const getMarked = (sections, selectedDateStr, primaryColor) => {
    const availableDays = {};
    const marked = {};
    for (const day of sections) {
      if (!isEmpty(day.data)) {
        availableDays[day.title] = true;
      }
    }
    for (const day of dates.monthRange.by("day")) {
      const title = day.format("YYYY-MM-DD");
      if (availableDays[title] && title === selectedDateStr) {
        marked[title] = {
          selected: true,
          selectedColor: primaryColor,
          marked: true,
        };
      } else if (availableDays[title]) {
        marked[title] = { marked: true, dotColor: primaryColor };
      } else if (title === selectedDateStr) {
        marked[title] = {
          selected: true,
          selectedColor: primaryColor,
          disabled: true,
          disableTouchEvent: true,
        };
      } else {
        marked[title] = {
          disabled: true,
          selectedColor: primaryColor,
          disableTouchEvent: true,
        };
      }
    }
    return marked;
  };

  const renderArrow = (direction) => {
    if (direction === "left") {
      return <ChevronLeftIcon />;
    } else {
      return <ChevronRightIcon />;
    }
  };

  if (loadingScreen) {
    return <LoadingScreen open={loadingScreen} />;
  }

  const calendarTheme = {
    backgroundColor: "transparent",
    calendarBackground: "transparent",
    textSectionTitleColor: theme.palette.text.primary,
    textSectionTitleDisabledColor: theme.palette.text.primary,
    selectedDayTextColor: theme.palette.text.primary,
    todayTextColor: theme.palette.text.primary,
    dayTextColor: theme.palette.text.primary,
    textDisabledColor: theme.palette.text.disabled,
    monthTextColor: theme.palette.text.primary,
    indicatorColor: theme.palette.primary.main,
  };

  return (
    <Box>
      <Container>
        <Paper elevation={2} style={styles.root}>
          <Container style={styles.textGrid}>
            <Grid container>
              <Grid xs={11} item>
                <Typography
                  gutterBottom
                  variant="h6"
                  color="textSecondary"
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Avatar
                    style={{ marginRight: 8 }}
                    alt={user.name}
                    src={user.picture}
                  />{" "}
                  {settings.eventPageTitle}
                </Typography>
                <Typography gutterBottom variant="h6" component="h6">
                  {event.title}
                </Typography>
                <Typography gutterBottom variant="body2">
                  {event.description}
                </Typography>
              </Grid>
              <Grid xs={1} item>
                <div style={{ float: "right" }}>
                  <IconButton
                    onClick={() =>
                      setThemeMode(mode === "light" ? "dark" : "light")
                    }
                    color="inherit"
                  >
                    {theme.palette.mode === "dark" ? (
                      <Brightness7Icon />
                    ) : (
                      <Brightness4Icon />
                    )}
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </Container>
          {theme.palette.mode === "dark" && (
            <Calendar
              displayLoadingIndicator={spinner}
              theme={calendarTheme}
              markedDates={times.marked}
              renderArrow={renderArrow}
              onDayPress={onDateChanged}
              onVisibleMonthsChange={onMonthChange}
            />
          )}
          {theme.palette.mode === "light" && (
            <Calendar
              displayLoadingIndicator={spinner}
              theme={calendarTheme}
              markedDates={times.marked}
              renderArrow={renderArrow}
              onDayPress={onDateChanged}
              onVisibleMonthsChange={onMonthChange}
            />
          )}
          <AgendaList
            sections={times.filtered}
            timezone={localSettings.timezone}
            setTimezone={(timezone) => {
              setLocalSettings({
                ...localSettings,
                timezone: timezone,
              });
              getAvailableTimes();
            }}
          />
        </Paper>
      </Container>
    </Box>
  );
};

export default ScheduleScreen;

const styles = StyleSheet.create({
  section: {
    color: "grey",
    textTransform: "capitalize",
  },
  nameGrid: {
    display: "flex",
    alignItems: "center",
  },
  root: {
    marginTop: 15,
  },
  textGrid: {
    paddingTop: 15,
  },
});
