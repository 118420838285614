import React, { useState, useEffect, useContext } from "react";
import { StyleSheet } from "react-native";
import {
  Button,
  Container,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  CircularProgress,
  TextField,
  TableContainer,
  TableRow,
  Table,
  TableHead,
  TableCell,
  TableBody,
  InputAdornment,
  Box,
} from "@mui/material";
import TimezoneSelect from "./TimezoneSelect";
import LoadingScreen from "./LoadingScreen";
import { defaultSettings } from "./constants";
import AccountBoxIcon from "@mui/icons-material/AccountBoxOutlined";
import SaveIcon from "@mui/icons-material/SaveOutlined";
import DvrOutlinedIcon from "@mui/icons-material/DvrOutlined";
import AuthContext from "./AuthContext";
const axios = require("axios").default;

export default (props) => {
  const { signIn } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [settings, setSettings] = useState(defaultSettings);
  const [username, setUsername] = useState(props.user.username);
  const [discountCode, setDiscountCode] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const saveSettings = async () => {
    setLoading(true);
    console.log("Saving settings", settings);
    axios
      .post(
        "https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/settings/save",
        { token: props.token, settings: settings, username: username }
      )
      .then((res) => {
        setLoading(false);
        console.log(res);
        if (props.user.username !== username) {
          signIn(props.token, { ...props.user, username: username });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getSettings = () => {
    setLoadingScreen(true);
    console.log("Get existing settings");
    axios
      .post(
        "https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/settings/get",
        { token: props.token }
      )
      .then((res) => {
        setLoadingScreen(false);
        if (res.data !== "") {
          setUsername(res.data.username);
          setSettings({
            ...settings,
            ...res.data.settings,
          });
        }
      });
  };

  const addDiscountCode = (e) => {
    e.preventDefault();
    if (discountPercentage < 0 || discountPercentage > 100) {
      setErrorMessage("Percent must be between 0 and 100.");
      return;
    } else {
      setErrorMessage("");
    }
    settings.discountCodes = [
      ...(settings.discountCodes || []),
      {
        code: discountCode,
        percentage: discountPercentage,
      },
    ];
    setSettings(settings);
    setDiscountCode("");
    setDiscountPercentage("");
  };

  const removeDiscountCode = (code) => {
    settings.discountCodes = (settings.discountCodes || []).filter(
      (x) => x.code !== code
    );
    setSettings(settings);
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <Box>
      <LoadingScreen open={loadingScreen} />
      {!loadingScreen && (
        <Container style={{ marginBottom: 20 }}>
          <Grid container direction="column" justifyContent="space-between">
            <Typography
              variant="h5"
              style={{
                marginTop: 20,
                flexDirection: "row",
                alignItems: "center",
                display: "flex",
              }}
              gutterBottom
            >
              <AccountBoxIcon style={{ marginRight: 10 }} /> Profile
            </Typography>
            <Grid item>
              <FormControl className="meetquick-input">
                <InputLabel id="timezone-label-id">Timezone</InputLabel>
                <TimezoneSelect
                  label="Timezone"
                  labelId="timezone-label-id"
                  value={settings.timezone}
                  onValueChange={(event) => {
                    setSettings({
                      ...settings,
                      timezone: event.target.value,
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                className="meetquick-input"
                label="Username"
                value={username}
                onChange={(event) => {
                  setUsername(event.target.value.replace(/[^a-z0-9-]/g, ""));
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                className="meetquick-input"
                label="Your Name"
                value={settings.yourName}
                onChange={(event) => {
                  setSettings({
                    ...settings,
                    yourName: event.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                className="meetquick-input"
                label="Event Page Title"
                value={settings.eventPageTitle}
                onChange={(event) => {
                  setSettings({
                    ...settings,
                    eventPageTitle: event.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                className="meetquick-input"
                label="Event Page Description"
                multiline
                minRows={3}
                value={settings.eventPageDescription}
                onChange={(event) => {
                  setSettings({
                    ...settings,
                    eventPageDescription: event.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item>
              <h3>Discount Codes</h3>
              <p style={{ color: "red" }}> {errorMessage}</p>
              <div>
                <form onSubmit={addDiscountCode}>
                  <TextField
                    style={{ marginRight: 10 }}
                    label="Discount Code"
                    value={discountCode}
                    onChange={(event) => {
                      setDiscountCode(event.target.value);
                    }}
                  />
                  <TextField
                    style={{ marginRight: 10 }}
                    label="Discount Percent"
                    value={discountPercentage}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    type="number"
                    onChange={(event) => {
                      setDiscountPercentage(event.target.value);
                    }}
                  />
                  <Button
                    style={{ height: 56 }}
                    variant="outlined"
                    color="primary"
                    type="submit"
                  >
                    Add
                  </Button>
                </form>
              </div>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Code</TableCell>
                      <TableCell>Discount</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(settings.discountCodes || []).map((row) => (
                      <TableRow key={row.code}>
                        <TableCell>{row.code}</TableCell>
                        <TableCell component="th" scope="row">
                          {row.percentage}%
                        </TableCell>
                        <TableCell align="right">
                          <Button onClick={() => removeDiscountCode(row.code)}>
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item>
              <div style={styles.gridItem}>
                <Button
                  style={styles.button}
                  variant="outlined"
                  color="primary"
                  onClick={saveSettings}
                >
                  {loading && (
                    <CircularProgress
                      style={{ marginRight: 10 }}
                      color="inherit"
                      size={20}
                    />
                  )}
                  <SaveIcon style={{ marginRight: 5 }} /> Save
                </Button>
                <Button
                  style={styles.button}
                  variant="outlined"
                  color="secondary"
                  onClick={() =>
                    window.open(`/schedule/${props.user.username}`)
                  }
                >
                  <DvrOutlinedIcon style={{ marginRight: 5 }} /> View Live
                  Events Page
                </Button>
              </div>
            </Grid>
          </Grid>
        </Container>
      )}
    </Box>
  );
};

const styles = StyleSheet.create({
  section: {
    color: "grey",
    textTransform: "capitalize",
  },
  nameGrid: {
    display: "flex",
    alignItems: "center",
  },
  root: {
    margin: 25,
  },
  gridItem: {
    marginTop: 20,
    minWidth: 300,
  },
  button: {
    marginRight: 15,
    marginBottom: 15,
    marginTop: 25,
  },
});
