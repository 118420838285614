import {
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Grid,
} from "@mui/material";

const MeetingPaymentLabel = ({
  e,
  discount,
  style,
}): { e: any, discount: any, style: any } => {
  if (e.meetingType !== "service") {
    return <div></div>;
  }
  var price = e.paymentAmount;
  if (discount && discount.percentage) {
    price = Math.floor(price - (price * parseInt(discount.percentage)) / 100);
  }
  return (
    <div style={style}>
      {e.paymentType && e.paymentType !== "none" && (
        <Grid container justify="space-between">
          {price != e.paymentAmount && (
            <Typography variant="h6" component="h5" style={{ marginRight: 8 }}>
              <s>${e.paymentAmount}</s>
            </Typography>
          )}
          <Typography align="left" variant="h5" component="h5">
            ${price}
          </Typography>
          {e.paymentType === "calculation" && (
            <Typography
              align="right"
              variant="caption"
              style={{ marginLeft: 5, marginTop: 9 }}
            >
              / {e.amountPerLabel}
            </Typography>
          )}
        </Grid>
      )}
    </div>
  );
};

export default MeetingPaymentLabel;
