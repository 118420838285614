import { Select, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { meetingOptions } from "./constants";
import PhoneIcon from "@mui/icons-material/Phone";
import SkypeImg from "./img/skype.svg";
import TeamsImg from "./img/teams.svg";
import MeetImg from "./img/meet.svg";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";

const MeetingTypeSelect = (props) => {
  const pickerItems = Object.keys(meetingOptions).map((key) => {
    const value = meetingOptions[key];
    if (value === "phone") {
      return (
        <MenuItem value={value} key={key}>
          <ListItemIcon>
            <PhoneIcon />
            <ListItemText style={{ marginLeft: 15 }} primary={key} />
          </ListItemIcon>
        </MenuItem>
      );
    } else if (value === "skype" || value === "skype_business") {
      return (
        <MenuItem value={value} key={key}>
          <ListItemIcon>
            <img src={SkypeImg} style={{ height: 30, width: 30 }} />
            <ListItemText style={{ marginLeft: 15 }} primary={key} />
          </ListItemIcon>
        </MenuItem>
      );
    } else if (value === "teams") {
      return (
        <MenuItem value={value} key={key}>
          <ListItemIcon>
            <img src={TeamsImg} style={{ height: 30, width: 30 }} />
            <ListItemText style={{ marginLeft: 15 }} primary={key} />
          </ListItemIcon>
        </MenuItem>
      );
    } else if (value === "google_meet") {
      return (
        <MenuItem value={value} key={key}>
          <ListItemIcon>
            <img src={MeetImg} style={{ height: 30, width: 30 }} />
            <ListItemText style={{ marginLeft: 15 }} primary={key} />
          </ListItemIcon>
        </MenuItem>
      );
    } else if (value === "service") {
      return (
        <MenuItem value={value} key={key}>
          <ListItemIcon>
            <HomeRepairServiceIcon />
            <ListItemText style={{ marginLeft: 15 }} primary={key} />
          </ListItemIcon>
        </MenuItem>
      );
    } else {
      return (
        <MenuItem value={value} key={key}>
          {key}
        </MenuItem>
      );
    }
  });

  return (
    <Select
      label={props.label}
      value={props.value || "phone"}
      onChange={props.onValueChange}
    >
      {pickerItems}
    </Select>
  );
};

export default MeetingTypeSelect;
