import { CalendarToday, Schedule } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { google, ics, office365, outlook, yahoo } from "calendar-link";
import moment from "moment-timezone";
import { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import AddressAutocomplete from "./AddressAutoComplete";
import AuthContext from "./AuthContext";
import LoadingScreen from "./LoadingScreen";
import MeetingPaymentLabel from "./MeetingPaymentLabel";
import { meetingOptionsLabel } from "./constants";
import { getMeetingTypeIcon } from "./utils";
const axios = require("axios").default;

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  cardElement: {
    height: "54px",
    marginBottom: "24px",
    border: "1px solid #ced4da",
    borderRadius: "4px",
    padding: "10px",
  },
});

const CheckoutForm = (params) => {
  const [success, setSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  console.log("CheckoutForm", params.isDarkMode);
  useEffect(() => {
    params.setStripe(stripe);
    params.setElements(elements);
  });
  return (
    <CardElement
      className={classes.cardElement}
      options={{
        style: {
          base: params.isDarkMode
            ? {
                color: "#fff",
                fontSize: "16px",
              }
            : {},
        },
      }}
    />
  );
};

const ScheduleBookingScreen = (props) => {
  const { setErrorMessage } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const [name, onNameChange] = useState("");
  const [email, onEmailChange] = useState("");
  const [note, onNoteChange] = useState("");
  const [phone, onPhoneChange] = useState("");
  const [address, onAddressChange] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const { username } = useParams();
  const [stripe, setStripe] = useState(null);
  const [elements, setElements] = useState(null);
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [stripePromise, setStripePromise] = useState(null);
  const [event, setEvent] = useState({});
  const [user, setUser] = useState({});
  const [discountCode, setDiscountCode] = useState("");
  const [validDiscountCode, setValidDicountCode] = useState("");
  const [validDiscount, setValidDiscount] = useState({});
  const [discountCodeError, setDiscountCodeError] = useState("");
  const [checkDiscountLoading, setCheckDiscountLoading] = useState(false);

  console.log("ScheduleBookingScreen", props.isDarkMode);

  const params = {
    username: username,
    id: searchParams.get("id"),
    start: searchParams.get("start"),
    timezone: searchParams.get("timezone"),
  };

  const getScheduleEventTitle = () => {
    console.log(event);
    if (event.meetingType === "service") {
      return `${event.title} - ${user.name}`;
    }
    return "Meeting with: " + user.name;
  };

  // set the timezone from url parameters
  moment.tz.setDefault(params.timezone);

  const scheduleEvent = {
    title: getScheduleEventTitle(),
    description: note,
    start: params.start,
    duration: [event.meetingLength, "minute"],
  };

  if (videoLink !== "") {
    scheduleEvent.description = note + "\n\nVideo Link\n" + videoLink;
  }

  if (success && event.redirectUrl) {
    window.location.replace(event.redirectUrl);
  }

  const onSubmit = async (e) => {
    console.log(e);
    e.preventDefault();
    const requestBody = {
      ...params,
      name: name,
      email: email,
      note: note || "No notes provided",
      phone: phone,
      address: address,
    };
    if (validDiscountCode) {
      requestBody.discountCode = validDiscountCode;
    }
    if (stripePromise && stripe && elements) {
      const card = elements.getElement(CardElement);
      const result = await stripe.createToken(card);
      console.log(result);
      if (result.error) {
        setErrorMessage(result.error.message);
        return;
      }
      requestBody["stripe"] = result.token;
    }
    setLoading(true);
    axios
      .post(
        "https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/schedule/reserve",
        requestBody
      )
      .then((res) => {
        if (res.data.videoLink) {
          setVideoLink(res.data.videoLink);
        }
        setLoading(false);
        setSuccess(true);
      })
      .catch((e) => {
        setLoading(false);
        const msg = e.response.data || "Failed to add event";
        setErrorMessage(msg, 4000);
      });
  };

  const validateDiscountCode = () => {
    if (!discountCode) {
      return;
    }
    setDiscountCodeError("");
    setCheckDiscountLoading(true);
    axios
      .post(
        "https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/discount/code",
        { ...params, discountCode: discountCode }
      )
      .then((res) => {
        if (res.data.discount) {
          setDiscountCode("");
          setValidDicountCode(res.data.discount.code);
          setValidDiscount(res.data.discount);
        } else {
          setDiscountCodeError("Could not find discount code");
        }
      })
      .catch((err) => {
        setDiscountCodeError("Could not find discount code");
      })
      .finally(() => {
        setCheckDiscountLoading(false);
      });
  };

  const submitButtonLabel = () => {
    if (event.meetingType === "service") {
      return "Schedule Service";
    }
    return "Schedule Event";
  };

  const getEventDetails = () => {
    axios
      .get(
        "https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/available/event/details",
        {
          params: {
            id: params.id,
            username: params.username,
          },
        }
      )
      .then((res) => {
        if (res.data.stripe) {
          setStripePromise(loadStripe(res.data.stripe));
        }
        setEvent(res.data.event);
        setUser(res.data.user);
        setLoadingScreen(false);
      });
  };

  useEffect(() => {
    getEventDetails();
  }, []);

  if (loadingScreen) {
    return <LoadingScreen open={loadingScreen} />;
  }

  return (
    <Box>
      <Container>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item>
            <Card>
              <CardContent>
                {success && (
                  <div>
                    <Typography gutterBottom variant="h5" component="h2">
                      Confirmed
                    </Typography>
                    <Typography gutterBottom variant="h6" component="h4">
                      You are scheduled with {user.name}.
                    </Typography>
                    <Chip
                      icon={<CalendarToday />}
                      style={{ marginRight: 10, marginBottom: 10 }}
                      label={moment(params.start).format(
                        "dddd, MMMM Do YYYY, h:mma"
                      )}
                    />
                    <Chip
                      avatar={getMeetingTypeIcon(event.meetingType)}
                      style={{ marginRight: 10, marginBottom: 10 }}
                      label={meetingOptionsLabel[event.meetingType]}
                    />
                    <Chip
                      icon={<Schedule />}
                      style={{ marginRight: 10, marginBottom: 10 }}
                      label={event.meetingLength + "m"}
                    />
                    <Typography gutterBottom variant="h6" component="h5">
                      Add to calendar
                    </Typography>
                    <Link
                      style={{ marginRight: 5 }}
                      href={google(scheduleEvent)}
                    >
                      Google
                    </Link>
                    <Divider />
                    <Link
                      style={{ marginRight: 5 }}
                      href={outlook(scheduleEvent)}
                    >
                      Outlook
                    </Link>
                    <Divider />
                    <Link
                      style={{ marginRight: 5 }}
                      href={office365(scheduleEvent)}
                    >
                      Office 365
                    </Link>
                    <Divider />
                    <Link
                      style={{ marginRight: 5 }}
                      href={yahoo(scheduleEvent)}
                    >
                      Yahoo
                    </Link>
                    <Divider />
                    <Link style={{ marginRight: 5 }} href={ics(scheduleEvent)}>
                      Download ICS
                    </Link>
                  </div>
                )}
                {!success && (
                  <div>
                    <Typography gutterBottom variant="h5" component="h2">
                      Enter Details
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h6"
                      color="textSecondary"
                      component="h4"
                    >
                      {event.name}
                    </Typography>
                    <Chip
                      avatar={<Avatar src={user.picture} />}
                      style={{ marginRight: 10, marginBottom: 20 }}
                      label={user.name}
                    />
                    <Chip
                      avatar={getMeetingTypeIcon(event.meetingType)}
                      style={{ marginRight: 10, marginBottom: 20 }}
                      label={meetingOptionsLabel[event.meetingType]}
                    />
                    <Chip
                      icon={<Schedule />}
                      style={{ marginRight: 10, marginBottom: 20 }}
                      label={event.meetingLength + "m"}
                    />
                    <Chip
                      icon={<CalendarToday />}
                      style={{ marginRight: 10, marginBottom: 20 }}
                      label={moment(params.start).format(
                        "dddd, MMMM Do YYYY, h:mma"
                      )}
                    />
                    <form onSubmit={onSubmit} autoComplete="off">
                      <Grid container direction="column">
                        <Grid item>
                          <TextField
                            required
                            style={{ marginBottom: 15 }}
                            fullWidth
                            name="name"
                            label="Name"
                            value={name}
                            onChange={(e) => onNameChange(e.target.value)}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            required
                            style={{ marginBottom: 15 }}
                            fullWidth
                            name="email"
                            label="Email"
                            value={email}
                            onChange={(e) => onEmailChange(e.target.value)}
                          />
                        </Grid>
                        {(event.meetingType === "phone" ||
                          event.meetingType === "service") && (
                          <Grid item>
                            <TextField
                              required
                              style={{ marginBottom: 15 }}
                              fullWidth
                              name="phone"
                              label="Phone Number"
                              value={phone}
                              onChange={(e) => onPhoneChange(e.target.value)}
                            />
                          </Grid>
                        )}
                        {(event.meetingType === "location" ||
                          event.meetingType === "service") && (
                          <Grid item>
                            <AddressAutocomplete
                              required
                              style={{ marginBottom: 15 }}
                              value={address}
                              onChange={(add) => onAddressChange(add)}
                            />
                          </Grid>
                        )}
                        <Grid item>
                          <TextField
                            style={{ marginBottom: 15 }}
                            fullWidth
                            name="note"
                            label="Note"
                            multiline
                            minRows={3}
                            value={note}
                            onChange={(e) => onNoteChange(e.target.value)}
                          />
                        </Grid>
                        {stripePromise && (
                          <Grid item xs={12}>
                            <div>
                              <Elements stripe={stripePromise}>
                                <CheckoutForm
                                  isDarkMode={props.isDarkMode}
                                  setElements={setElements}
                                  setStripe={setStripe}
                                />
                              </Elements>
                            </div>
                          </Grid>
                        )}
                        {event.meetingType === "service" && (
                          <Grid item xs={12}>
                            <div style={{ marginTop: 30, marginBottom: 20 }}>
                              <TextField
                                style={{
                                  marginRight: 10,
                                }}
                                label="Discount Code"
                                value={discountCode}
                                size="small"
                                onChange={(e) =>
                                  setDiscountCode(e.target.value)
                                }
                              />
                              <Button
                                style={{ height: 40 }}
                                variant="outlined"
                                color="primary"
                                type="button"
                                onClick={() => validateDiscountCode()}
                              >
                                {checkDiscountLoading && (
                                  <CircularProgress
                                    style={{ marginRight: 10 }}
                                    color="inherit"
                                    size={20}
                                  />
                                )}
                                Add
                              </Button>
                            </div>
                            {discountCodeError && (
                              <div style={{ width: "100%", marginBottom: 10 }}>
                                <Alert severity="error">
                                  {discountCodeError}
                                </Alert>
                              </div>
                            )}
                            {validDiscountCode && (
                              <div style={{ width: "100%", marginBottom: 10 }}>
                                <p>Code Applied - {validDiscountCode}</p>
                              </div>
                            )}
                            <MeetingPaymentLabel
                              style={{ marginBottom: 20, marginLeft: 5 }}
                              discount={validDiscount}
                              e={event}
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Button variant="contained" color="primary" type="submit">
                        {loading && (
                          <CircularProgress
                            style={{ marginRight: 10 }}
                            color="inherit"
                            size={20}
                          />
                        )}
                        {submitButtonLabel()}
                      </Button>
                    </form>
                  </div>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ScheduleBookingScreen;
