import { Select, MenuItem } from "@mui/material";

const meetingLengthMap = {
  "15 min": 15,
  "30 min": 30,
  "45 min": 45,
  "60 min": 60,
  "90 min": 90,
  "120 min": 120,
  "2.5 hrs": 150,
  "3 hrs": 180,
  "3.5 hrs": 210,
  "4 hrs": 240,
};

const MeetingLength = (props) => {
  const pickerItems = Object.keys(meetingLengthMap).map((key) => {
    const value = meetingLengthMap[key];
    return (
      <MenuItem value={value} key={key}>
        {key}
      </MenuItem>
    );
  });

  return (
    <Select
      label={props.label}
      labelId={props.labelId}
      value={props.value}
      onChange={props.onValueChange}
    >
      {pickerItems}
    </Select>
  );
};

export default MeetingLength;
