import { useState, useEffect, useContext } from 'react'
import { Box, Container, Grid, Typography, Paper, Link, Divider, Switch, FormControlLabel, FormGroup, Button, Chip, IconButton } from '@mui/material'
import { Schedule } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { defaultEventSettings } from './constants'
import { randomColor } from './utils'
import LoadingScreen from './LoadingScreen'
import EventIcon from '@mui/icons-material/EventOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/EditOutlined'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DvrOutlinedIcon from '@mui/icons-material/DvrOutlined'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import AuthContext from './AuthContext'
const axios = require('axios').default

const defaultMeetings = []

const EventsScreen = (props) => {
  const { share } = useContext(AuthContext)
  const [loadingScreen, setLoadingScreen] = useState(true)
  const [events, setEvents] = useState(defaultMeetings)
  const [settings, setSettings] = useState({})
  const navigate = useNavigate()

  const handleEditClick = (e) => {
    navigate(`edit/${e.id}`, {state: {event: e}})
  }

  const handleEnableChange = (event, index) => {
    var selectedEvent = {}
    const newEvents = events.map((e, i) => {
      if (i === index) {
        selectedEvent = {
          ...e,
          enabled: event.target.checked
        }
        return selectedEvent
      } else {
        return e
      }
    })
    setEvents(newEvents)
    saveEvents(selectedEvent)
  }

  const handleDeleteClick = (event, index) => {
    const newEvents = events.filter((e, i) => {
      return e.id !== event.id
    })
    setLoadingScreen(true)
    axios.post('https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/events/delete', {token: props.token, id: event.id})
    .then(res => {
      setLoadingScreen(false)
      console.log(res)
      setEvents(newEvents)
    })
  }

  const saveEvents = (e) => {
    setLoadingScreen(true)
    axios.post('https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/events/save', {token: props.token, event: e})
    .then(res => {
      setLoadingScreen(false)
      console.log(res)
    })
  }

  const createNewEvent = (e) => {
    console.log('Creating new event')
    navigate(`create`, {state: {event: defaultEventSettings}})
  }

  const getEvents = () => {
    setLoadingScreen(true)
    axios.post('https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/events/list', {token: props.token})
    .then(res => {
      setLoadingScreen(false)
      if (res.data !== '') {
        setSettings(res.data.settings)
        setEvents(res.data.events)
      }
    })
  }

  useEffect(() => {
    getEvents()
  }, [])

  return (
    <Box>
      <LoadingScreen open={loadingScreen} />
      { !loadingScreen &&
        (<Container style={{marginBottom: 20}}>
          <Typography variant='h5' style={{marginTop: 20, flexDirection: 'row', alignItems: 'center', display: 'flex'}} gutterBottom>
            <EventIcon style={{marginRight: 10}} /> Events
          </Typography>
          <Typography variant='caption' style={{marginTop: 10, flexDirection: 'row', alignItems: 'center', display: 'flex'}} gutterBottom>
            Use events to define length, location, and agenda for your scheduled events.
          </Typography>
          <Button variant='outlined' color='primary' onClick={createNewEvent} style={{marginTop: 15, marginRight: 15}}>
            <AddCircleOutlineIcon style={{marginRight: 5}} /> Create new event type
          </Button>
          <Button variant='outlined' color='secondary' onClick={() => window.open(`/schedule/${props.user.username}`)} style={{marginTop: 15, marginRight: 15}}>
            <DvrOutlinedIcon style={{marginRight: 5}} /> View live events page</Button>
          <Button variant='outlined' color='secondary' onClick={() => share(settings.eventPageTitle, settings.eventPageDescription, `/schedule/${props.user.username}`)} style={{marginTop: 15, marginRight: 15}}>
            <IosShareOutlinedIcon style={{marginRight: 5}} /> Share events page</Button>
          <Grid container spacing={3} style={{marginTop: 15}}>
            {events.map((e, i) => (
              <Grid item xs={12} sm={6} key={e.id}>
                <Paper elevation={2}>
                  <div style={{marginLeft: 15, marginTop: 15, display: 'inline-block', borderRadius: '50%', width: 12, height: 12, backgroundColor: e.color || randomColor()}} />
                  <IconButton style={{float: 'right'}} onClick={() => share(e.title, e.description, `/schedule/${props.user.username}/${e.link}`)}>
                    <IosShareOutlinedIcon />
                  </IconButton>
                  <Typography variant='h6' style={{marginLeft: 15}}>{ e.title }</Typography>
                  <Link rel='noopener noreferrer' target='_blank' href={`/schedule/${props.user.username}/${e.link}`} variant='body2' style={{marginTop: 15, marginLeft: 15}}>
                    { `/${e.link}` }
                  </Link>
                  <Divider style={{marginTop: 15}} />
                  <FormGroup style={{marginTop: 15, marginLeft: 25}}>
                    <FormControlLabel control={<Switch
                      size='small'
                      checked={e.enabled}
                      onChange={event => handleEnableChange(event, i)}
                      inputProps={{ 'aria-label': 'secondary checkbox' }} />
                  } label='Enabled' />
                  </FormGroup>
                  <Chip icon={<Schedule />} style={{marginTop: 15, marginBottom: 15, marginLeft: 15}} label={e.meetingLength + ' Minutes'} />
                  <Typography style={{marginBottom: 15, marginLeft: 15, minHeight: 60}}>{ e.description }</Typography>
                  <Divider style={{marginTop: 15}} />
                  <Button style={{marginTop: 15, marginBottom: 15, marginLeft: 15}} variant='outlined' color='primary' onClick={event => handleEditClick(e)}>
                    <EditIcon style={{marginRight: 5}} /> Edit
                  </Button>
                  <Button style={{marginTop: 15, marginBottom: 15, marginLeft: 15}} variant='outlined' color='secondary' onClick={event => handleDeleteClick(e)}>
                    <DeleteIcon style={{marginRight: 5}} /> Delete
                  </Button>
                </Paper>
              </Grid>
          ))}
          </Grid>
        </Container>
      )}
    </Box>
  )
}

export default EventsScreen
