import { Select, MenuItem } from '@mui/material'

const timeOptions = {
  '12:00am': 0,
  '12:15am': 15,
  '12:30am': 30,
  '12:45am': 45,
  '1:00am': 100,
  '1:15am': 115,
  '1:30am': 130,
  '1:45am': 145,
  '2:00am': 200,
  '2:15am': 215,
  '2:30am': 230,
  '2:45am': 245,
  '3:00am': 300,
  '3:15am': 315,
  '3:30am': 330,
  '3:45am': 345,
  '4:00am': 400,
  '4:15am': 415,
  '4:30am': 430,
  '4:45am': 445,
  '5:00am': 500,
  '5:15am': 515,
  '5:30am': 530,
  '5:45am': 545,
  '6:00am': 600,
  '6:15am': 615,
  '6:30am': 630,
  '6:45am': 645,
  '7:00am': 700,
  '7:15am': 715,
  '7:30am': 730,
  '7:45am': 745,
  '8:00am': 800,
  '8:15am': 815,
  '8:30am': 830,
  '8:45am': 845,
  '9:00am': 900,
  '9:15am': 915,
  '9:30am': 930,
  '9:45am': 945,
  '10:00am': 1000,
  '10:15am': 1015,
  '10:30am': 1030,
  '10:45am': 1045,
  '11:00am': 1100,
  '11:15am': 1115,
  '11:30am': 1130,
  '11:45am': 1145,
  '12:00pm': 1200,
  '12:15pm': 1215,
  '12:30pm': 1230,
  '12:45pm': 1245,
  '1:00pm': 1300,
  '1:10pm': 1315,
  '1:30pm': 1330,
  '1:45pm': 1345,
  '2:00pm': 1400,
  '2:15pm': 1415,
  '2:30pm': 1430,
  '2:45pm': 1445,
  '3:00pm': 1500,
  '3:15pm': 1515,
  '3:30pm': 1530,
  '3:45pm': 1545,
  '4:00pm': 1600,
  '4:15pm': 1615,
  '4:30pm': 1630,
  '4:45pm': 1645,
  '5:00pm': 1700,
  '5:15pm': 1715,
  '5:30pm': 1730,
  '5:45pm': 1745,
  '6:00pm': 1800,
  '6:15pm': 1815,
  '6:30pm': 1830,
  '6:45pm': 1845,
  '7:00pm': 1900,
  '7:15pm': 1915,
  '7:30pm': 1930,
  '7:45pm': 1945,
  '8:00pm': 2000,
  '8:15pm': 2015,
  '8:30pm': 2030,
  '8:45pm': 2045,
  '9:00pm': 2100,
  '9:15pm': 2115,
  '9:30pm': 2130,
  '9:45pm': 2145,
  '10:00pm': 2200,
  '10:15pm': 2215,
  '10:30pm': 2230,
  '10:45pm': 2245,
  '11:00pm': 2300,
  '11:15pm': 2315,
  '11:30pm': 2330,
  '11:45pm': 2345
}

const TimeSelect = (props) => {
  const pickerItems = Object.keys(timeOptions).map(key => {
    const value = timeOptions[key]
    return <MenuItem value={value} key={key}>{key}</MenuItem>
  })

  return (
    <Select
      value={props.value}
      onChange={props.onValueChange}>
      {pickerItems}
    </Select>
  )
}

export default TimeSelect
