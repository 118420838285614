import {
  List,
  ListItemButton,
  ListItem,
  Button,
  ListItemText,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import TimezoneSelect from "./TimezoneSelect";
import Moment from "moment-timezone";
import { extendMoment } from "moment-range";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
const moment = extendMoment(Moment);

const AgendaList = (props) => {
  const navigate = useNavigate();
  moment.tz.setDefault(props.timezone);

  const getTitle = (title) => {
    const t = moment(title);
    const tt = t.format("dddd, MMMM D");
    if (tt === moment().format("dddd, MMMM D")) {
      return "Today, " + tt;
    }
    return tt;
  };

  if (isEmpty(props.sections)) {
    return <div />;
  }

  const buttonPressed = (item) => () => {
    navigate(`/schedule/${item.username}/reserve` + buildQueryStr(item));
  };

  const buildQueryStr = (item) => {
    if (!isEmpty(item)) {
      return `?start=${item["start"]}&id=${item["id"]}&timezone=${props.timezone}`;
    }
    return "";
  };

  const getListItem = (day) => {
    if (isEmpty(day)) {
      return (
        <ListItem key="unique">
          <ListItemText secondary="No Available Times Today" />
        </ListItem>
      );
    } else {
      return (
        <ListItemButton key={day.hour} onClick={buttonPressed(day)}>
          <ListItemText
            sx={{ flex: "none", marginRight: 5 }}
            primary={day.hour}
            secondary={day.meetingLength + "m"}
            primaryTypographyProps={{ variant: "h7", component: "h4" }}
          />
          <ListItemText
            className="schedule-available-text"
            primary="Available booking"
            primaryTypographyProps={{ variant: "h7", component: "h4" }}
          />
          <ListItemText primary="" />
          <Button
            variant="outlined"
            color="primary"
            onClick={buttonPressed(day)}
          >
            Reserve
          </Button>
        </ListItemButton>
      );
    }
  };

  return (
    <div>
      {props.sections.map((section) => (
        <List key={section.title}>
          <ListItem key="header">
            <Typography style={{ flexGrow: 1, marginRight: 10 }}>
              {getTitle(section.title)}
            </Typography>
            <FormControl sx={{ maxWidth: "30vw" }} size="small">
              <InputLabel id="timezone-label-id">Timezone</InputLabel>
              <TimezoneSelect
                label="Timezone"
                labelId="timezone-label-id"
                value={props.timezone}
                onValueChange={(event) => {
                  props.setTimezone(event.target.value);
                }}
              />
            </FormControl>
          </ListItem>
          <ListItem>
            <div id="amzn-assoc-ad-10459539-662e-484c-9581-19b93ebbd739" />
            <script
              async
              src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=10459539-662e-484c-9581-19b93ebbd739"
            />
          </ListItem>
          {section.data.map((day) => getListItem(day))}
        </List>
      ))}
    </div>
  );
};

export default AgendaList;
