import { Select, MenuItem } from "@mui/material";

const timeOptions = {
  "1 Hours": 60,
  "2 Hours": 120,
  "3 Hours": 180,
  "6 Hours": 360,
  "12 Hours": 720,
  "24 Hours": 1440,
  "48 Hours": 2880,
  "7 Days": 10080,
  "14 Days": 20160,
  "30 Days": 43200,
};
const defaultHours = 120;

const BufferTimeSelect = (props) => {
  const pickerItems = Object.keys(timeOptions).map((key) => {
    const value = timeOptions[key];
    return (
      <MenuItem value={value} key={key}>
        {key}
      </MenuItem>
    );
  });

  return (
    <Select
      label={props.label}
      value={props.value || defaultHours}
      onChange={props.onValueChange}
    >
      {pickerItems}
    </Select>
  );
};

export default BufferTimeSelect;
