import "@fontsource/roboto";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { getTheme } from "./components/theme";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { Snackbar, Alert } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import HomeScreen from "./components/HomeScreen";
import LoginScreen from "./components/LoginScreen";
import DashboardScreen from "./components/DashboardScreen";
import AuthContext from "./components/AuthContext";
import ScheduleScreen from "./components/ScheduleScreen";
import ScheduleBookingScreen from "./components/ScheduleBookingScreen";
import EventEditScreen from "./components/EventEditScreen";
import CalendarsConnectScreen from "./components/CalendarConnectScreen";
import ScheduleEventsScreen from "./components/ScheduleEventsScreen";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import { shareThis } from "./components/share";
import "./styles.css";

const App = () => {
  const [user, setUser] = useState({});
  const [token, setToken] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [mode, setMode] = useState("system");
  const [homeMode, setHomeMode] = useState("empty");
  const [primaryColor, setPrimaryColor] = useState("#00ABB3");
  const [secondaryColor, setSecondaryColor] = useState("#656b78");
  const [snackbarOptions, setSnackbarOptions] = useState({
    open: false,
    message: "",
    autoHideDuration: 6000,
    severity: "info",
  });

  const setInfoMessage = (message, time) => {
    console.log("Sending info message", message, time);
    setSnackbarOptions({
      ...snackbarOptions,
      open: true,
      severity: "info",
      message: message,
      autoHideDuration: time,
    });
  };

  const setErrorMessage = (message, time) => {
    console.log("Sending error message", message, time);
    setSnackbarOptions({
      ...snackbarOptions,
      open: true,
      severity: "error",
      message: message,
      autoHideDuration: time,
    });
  };

  const share = (title, description, url) => {
    shareThis({ setInfoMessage: setInfoMessage }, title, description, url);
  };

  var isDarkMode = false;
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  var theme = null;
  if (mode === "system") {
    theme = getTheme(
      prefersDarkMode ? "dark" : "light",
      primaryColor,
      secondaryColor
    );
    isDarkMode = prefersDarkMode;
  } else if (homeMode !== "empty") {
    theme = getTheme(homeMode, primaryColor, secondaryColor);
  } else {
    theme = getTheme(mode, primaryColor, secondaryColor);
    isDarkMode = mode === "dark";
  }

  const updateThemeColors = (mode, primary, secondary) => {
    console.log(mode, primary, secondary);
    setMode(mode);
    setPrimaryColor(primary);
    setSecondaryColor(secondary);
  };

  const signIn = (userToken, userObject) => {
    console.log("Sign in");
    window.localStorage.setItem("user", JSON.stringify(userObject));
    window.localStorage.setItem("token", JSON.stringify(userToken));
    setUser(userObject);
    setToken(userToken);
    setLoggedIn(true);
    setLoaded(true);
  };
  const signOut = () => {
    console.log("Sign out");
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("token");
    setUser({});
    setToken({});
    setLoggedIn(false);
  };

  const setThemeMode = (mode) => {
    window.localStorage.setItem("mode", mode);
    setMode(mode);
  };

  const authContext = {
    loaded: loaded,
    loggedIn: loggedIn,
    user: user,
    setUser: setUser,
    token: token,
    setToken: setToken,
    signIn: signIn,
    signOut: signOut,
    mode: mode,
    theme: theme,
    setThemeMode: setThemeMode,
    setMode: setMode,
    updateThemeColors: updateThemeColors,
    primaryColor: primaryColor,
    secondaryColor: secondaryColor,
    setSnackbarOptions: setSnackbarOptions,
    setInfoMessage: setInfoMessage,
    setErrorMessage: setErrorMessage,
    share: share,
  };

  const restoreToken = (callback) => {
    const loggedInUser = window.localStorage.getItem("user");
    const loggedInToken = window.localStorage.getItem("token");
    const mode = window.localStorage.getItem("mode");
    if (mode) {
      setMode(mode);
    }
    if (loggedInUser && loggedInToken) {
      const foundUser = JSON.parse(loggedInUser);
      const foundToken = JSON.parse(loggedInToken);

      setUser(foundUser);
      setToken(foundToken);
      setLoggedIn(true);
    }
    setLoaded(true);
  };

  useEffect(() => {
    restoreToken();
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GoogleOAuthProvider clientId="999330930004-g0uio34mtlqeddfr0qgkheec94gaing9.apps.googleusercontent.com">
          <CssBaseline />
          <AuthContext.Provider value={authContext}>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snackbarOptions.open}
              autoHideDuration={snackbarOptions.autoHideDuration}
              onClose={() =>
                setSnackbarOptions({ ...snackbarOptions, open: false })
              }
            >
              <Alert severity={snackbarOptions.severity} sx={{ width: "100%" }}>
                {snackbarOptions.message}
              </Alert>
            </Snackbar>
            <Router>
              <Routes>
                <Route
                  path="/"
                  index
                  element={
                    <HomeScreen
                      token={token}
                      user={user}
                      setHomeMode={setHomeMode}
                    />
                  }
                />
                <Route path="login" element={<LoginScreen />} />
                <Route
                  path="dashboard/:current"
                  element={<DashboardScreen />}
                />
                <Route
                  path="dashboard/events/edit/:eventId"
                  element={
                    <EventEditScreen token={token} user={user} create={false} />
                  }
                />
                <Route
                  path="dashboard/events/create"
                  element={<EventEditScreen token={token} user={user} create />}
                />
                <Route
                  path="dashboard/calendars/connect"
                  element={<CalendarsConnectScreen token={token} user={user} />}
                />
                <Route
                  path="schedule/:username/:link"
                  element={<ScheduleScreen />}
                />
                <Route
                  path="schedule/:username"
                  element={<ScheduleEventsScreen />}
                />
                <Route
                  path="schedule/:username/reserve"
                  element={<ScheduleBookingScreen isDarkMode={isDarkMode} />}
                />
                <Route path="terms" element={<Terms />} />
                <Route path="privacy" element={<Privacy />} />
              </Routes>
            </Router>
          </AuthContext.Provider>
        </GoogleOAuthProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
