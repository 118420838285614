import React from 'react'

import { Button } from '@mui/material'
import { useSearchParams, useNavigate } from 'react-router-dom'
import MsLogo from './img/ms_logo.svg'
const axios = require('axios').default

export default (props) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const redirectTo = window.location.href.split('?')[0]

  if (props.token && props.token.refresh_token && searchParams.get('code')) {
    props.setLoading(true)
    axios.post('https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/microsoft/connect', {
      token: props.token,
      code: searchParams.get('code'),
      redirectTo: redirectTo
    })
    .then(res => {
      navigate('/dashboard/calendars')
    })
  }

  const openMs = () => {
    props.setLoading(true)
    axios.post('https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/microsoft/auth', {
      redirectTo: redirectTo
    })
    .then(res => {
      window.location.assign(res.data.authCodeUrl)
    })
  }

  return (
    <div style={{marginTop: 10}}>
      <Button style={{backgroundColor: '#2F2F2F', height: 55, width: '100%', display: 'block'}} onClick={openMs}>
        <img style={{margin: 6, textAlign: 'center', display: 'block', float: 'left', whiteSpace: 'nowrap'}} src={MsLogo} />
        <p style={{color: '#FFFFFF', fontFamily: 'Segoe UI Regular', margin: 4, fontSize: '15px', fontWeigth: 600, textTransform: 'none'}}>
        Sign in with Microsoft
      </p>
      </Button>
    </div>
  )
}
