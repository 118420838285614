import { useState } from 'react'
import { Box, Container, Grid, CardContent, Card, Typography, CircularProgress } from '@mui/material'
import GoogleButton from './GoogleButton'
import MicrosoftButton from './MicrosoftButton'
import { useNavigate } from 'react-router-dom'

const CalendarConnectScreen = (props) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const setUser = (e) => {
    console.log('Set user', e)
    navigate(-1)
  }

  return (
    <Box>
      <Container>
        <Grid container justifyContent='center' alignItems='center' style={{minHeight: '100vh'}}>
          <Grid item>
            <Card>
              <CardContent>
                <Typography gutterBottom variant='h5' component='h2'>
                  Connect Calendar {loading && <CircularProgress style={{marginLeft: 10, float: 'right'}} color='inherit' size={20} />}
                </Typography>
                <Typography gutterBottom variant='body2' color='textSecondary' component='p'>
                  Authorize calendar access to get started
                </Typography>
                <GoogleButton style={{marginTop: 10}} login={false} setUser={setUser} setLoading={setLoading} user={props.user} token={props.token} />
                <MicrosoftButton style={{marginTop: 10}} login={false} setUser={setUser} setLoading={setLoading} user={props.user} token={props.token} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default CalendarConnectScreen
