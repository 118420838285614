import { useContext, useEffect, useState } from "react";
import AuthContext from "./AuthContext";
import makeStyles from "@mui/styles/makeStyles";
import {
  Button,
  Box,
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Avatar,
  Container,
  Link,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import SearchIcon from "@mui/icons-material/Search";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import CodeIcon from "@mui/icons-material/Code";
import { useNavigate } from "react-router-dom";
import HeroSection from "./HeroSection";
import { ParallaxProvider, ParallaxBanner } from "react-scroll-parallax";
import Logo from "./img/logo.svg";
import CalendarSide from "./img/cal_3.jpg";

const HomeScreen = (props) => {
  const [isShrunk, setShrunk] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const { loggedIn } = useContext(AuthContext);
  const standalone =
    window.navigator &&
    "standalone" in window.navigator &&
    window.navigator.standalone;

  useEffect(() => {
    props.setHomeMode("light");
    const handler = () => {
      setShrunk((isShrunk) => {
        if (
          !isShrunk &&
          (document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20)
        ) {
          return true;
        }

        if (
          isShrunk &&
          document.body.scrollTop < 4 &&
          document.documentElement.scrollTop < 4
        ) {
          return false;
        }

        return isShrunk;
      });
    };
    window.addEventListener("scroll", handler);
    return () => {
      window.removeEventListener("scroll", handler);
      props.setHomeMode("empty");
    };
  }, []);

  return (
    <ParallaxProvider>
      <Box>
        <AppBar elevation={0} style={{ marginTop: standalone ? 60 : 0 }}>
          <Toolbar>
            <Box
              className="meetquick-logo"
              component="img"
              src={Logo}
              alt="Meet quick logo"
              style={{ height: isShrunk ? 54 : 74, margin: 10 }}
            />
            <Typography style={{ flexGrow: 1 }} />
            {loggedIn && (
              <div style={{ display: "flex" }}>
                <Avatar
                  alt={props.user.name}
                  src={props.user.picture}
                  style={{ marginRight: 10 }}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => navigate("dashboard/events")}
                >
                  Dashboard
                </Button>
              </div>
            )}
            {!loggedIn && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => navigate("login")}
              >
                Sign In
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <HeroSection
          title="MeetQuick"
          subtitle="Make scheduling a breeze for clients and prospects to call or meet with you"
          imgSrc="https://r.mobirisesite.com/273891/assets/images/mbr-1908x1920.png?v=1Pdg8e"
          onSignupClick={() => navigate("login")}
          onLoginClick={() => navigate("login")}
        />
        <Container>
          <div style={{ marginTop: 50, marginBottom: 50 }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={12} sm={12} className={classes.item}>
                <Typography variant="h3" component="h3">
                  Features
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} className={classes.item}>
                <FormatListNumberedIcon
                  className={classes.icon}
                  color="primary"
                />
                <Typography variant="h6" component="h6">
                  Multiple Calendars
                </Typography>
                <Typography variant="p" component="p">
                  Connect up to 10 calendars (work, personal, etc.) to reflect
                  true availability
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} className={classes.item}>
                <SearchIcon className={classes.icon} color="primary" />
                <Typography variant="h6" component="h6">
                  Holistic View
                </Typography>
                <Typography variant="p" component="p">
                  Easily see all of your scheduled events across all connected
                  calendars
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} className={classes.item}>
                <PhoneAndroidIcon className={classes.icon} color="primary" />
                <Typography variant="h6" component="h6">
                  Mobile Friendly
                </Typography>
                <Typography variant="p" component="p">
                  Effortless interface for on-the-go scheduling
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} className={classes.item}>
                <CodeIcon className={classes.icon} color="primary" />
                <Typography variant="h6" component="h6">
                  No Coding
                </Typography>
                <Typography variant="p" component="p">
                  Seamlessly create various event types, customizable
                  availability windows, and branded scheduling links
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} className={classes.item}>
                <FavoriteBorderIcon className={classes.icon} color="primary" />
                <Typography variant="h6" component="h6">
                  Free
                </Typography>
                <Typography variant="p" component="p">
                  While we may add additional features later, we feel that
                  scheduling should be financially frictionless
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Container>
        <Container>
          <Grid container style={{ minHeight: 470 }}>
            <Grid xs={12} sm={6} style={{ textAlign: "center" }} item>
              <div style={{ height: 400 }}>
                <ParallaxBanner
                  style={{ height: 400 }}
                  speed={-20}
                  layers={[{ image: CalendarSide, speed: -20 }]}
                />
              </div>
            </Grid>
            <Grid xs={12} sm={6} item>
              <Box
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h5" component="h5">
                  Join our Discord
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ margin: 20, textAlign: "center" }}
                >
                  Chat in realtime with the founders and MeetQuick community
                </Typography>
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ margin: 10 }}
                  onClick={() => window.open("https://discord.gg/m87m4Xrg")}
                >
                  Join
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Container>
          <Grid container style={{ marginTop: 30, marginBottom: 30 }}>
            <Grid xs={12} style={{ textAlign: "center" }} item>
              <Typography variant="h5" component="h5">
                FAQ
              </Typography>
            </Grid>
            <Grid xs={12} style={{ textAlign: "center" }} item>
              <List style={{ marginLeft: "10vw", marginRight: "10vw" }}>
                <ListItem>
                  <ListItemText
                    primary="Q: How do you keep MeetQuick free?"
                    secondary="A: MeetQuick will always be free, even for the premium features. To support the hosting costs we serve ads on the booking pages."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Q: Can I customize my booking page?"
                    secondary="A: Yes! MeetQuick provides customization of colors, text, and image."
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
        <Container>
          <Grid container style={{ marginTop: 55, marginBottom: 25 }}>
            <Grid item xs={12} sm={3} style={{ textAlign: "center" }}>
              <Link href="/privacy" className={classes.link}>
                Privacy
              </Link>
              <Link href="/terms" className={classes.link}>
                Terms
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
              <Typography variant="caption">
                Copyright MeetQuick 2023
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} style={{ textAlign: "center" }}>
              <Link href="https://discord.gg/m87m4Xrg" className={classes.link}>
                Support
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ParallaxProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  item: {
    textAlign: "center",
    margin: theme.spacing(2),
  },
  icon: {
    width: 55,
    height: 55,
  },
  link: {
    margin: 10,
  },
}));

export default HomeScreen;
