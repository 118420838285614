import { Select, MenuItem } from "@mui/material";

const meetingLengthMap = {
  None: "none",
  Fixed: "fixed",
  Calculation: "calculation",
};

const MeetingPaymentTypeSelect = (props) => {
  const pickerItems = Object.keys(meetingLengthMap).map((key) => {
    const value = meetingLengthMap[key];
    return (
      <MenuItem value={value} key={key}>
        {key}
      </MenuItem>
    );
  });

  return (
    <Select
      label={props.label}
      labelId={props.labelId}
      value={props.value}
      onChange={props.onValueChange}
    >
      {pickerItems}
    </Select>
  );
};

export default MeetingPaymentTypeSelect;
