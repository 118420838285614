export const shareThis = (authContext, title, text, url) => {
  const { setInfoMessage } = authContext
  const ahref = window.location.origin
  if (navigator.share) {
    navigator.share({
      title: title,
      text: text,
      url: ahref + url
    })
    .then(() => console.log('Successful share'))
    .catch((error) => console.log('Error sharing', error))
  } else {
    console.log('Not supported adding to clipboard')
    navigator.clipboard.writeText(ahref + url)
    setInfoMessage('Copied to clipboard', 2000)
  }
}
