import { Select, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import GoogleImg from "./img/g-logo.svg";
import MsImg from "./img/ms_logo.svg";
import StripeImg from "./img/stripe.svg";

const MeetingAccountSelect = (props) => {
  const pickerItems = props.accounts.map((account) => {
    if (account.provider === "Google") {
      return (
        <MenuItem value={account.id} key={account.id}>
          <ListItemIcon>
            <img src={GoogleImg} style={{ height: 30, width: 30 }} />
            <ListItemText
              style={{ marginLeft: 15 }}
              primary={account.info.email}
            />
          </ListItemIcon>
        </MenuItem>
      );
    } else if (account.provider === "Microsoft") {
      return (
        <MenuItem value={account.id} key={account.id}>
          <ListItemIcon>
            <img src={MsImg} style={{ height: 30, width: 30 }} />
            <ListItemText
              style={{ marginLeft: 15 }}
              primary={account.info.email}
            />
          </ListItemIcon>
        </MenuItem>
      );
    } else if (account.provider === "Stripe") {
      return (
        <MenuItem value={account.id} key={account.id}>
          <ListItemIcon>
            <img src={StripeImg} style={{ height: 30, width: 30 }} />
            <ListItemText style={{ marginLeft: 15 }} primary={account.name} />
          </ListItemIcon>
        </MenuItem>
      );
    } else {
      return (
        <MenuItem value={account.id} key={account.id}>
          {account.info.email}
        </MenuItem>
      );
    }
  });

  return (
    <Select
      label={props.label}
      value={props.value}
      onChange={props.onValueChange}
    >
      {pickerItems}
    </Select>
  );
};

export default MeetingAccountSelect;
