import PhoneIcon from "@mui/icons-material/Phone";
import SkypeImg from "./img/skype.svg";
import TeamsImg from "./img/teams.svg";
import MeetImg from "./img/meet.svg";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";

export const randomColor = () => {
  const randomC = Math.floor(Math.random() * 16777215).toString(16);
  return `#${randomC}`;
};

export const getMeetingTypeIcon = (value) => {
  if (value === "phone") {
    return <PhoneIcon />;
  } else if (value === "skype" || value === "skype_business") {
    return <img src={SkypeImg} style={{ height: 25, width: 25 }} />;
  } else if (value === "teams") {
    return <img src={TeamsImg} style={{ height: 25, width: 25 }} />;
  } else if (value === "google_meet") {
    return <img src={MeetImg} style={{ height: 25, width: 25 }} />;
  } else if (value === "service") {
    return <HomeRepairServiceIcon />;
  } else {
    return <PhoneIcon />;
  }
};
