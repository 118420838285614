import React from "react";
import { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { set } from "lodash";

const AddressAutocomplete = ({ style, value, onChange, required }) => {
  const [options, setOptions] = useState([]);
  const [autocomplete, setAutocomplete] = useState(null);

  const handleAddressChange = async (event, newValue) => {
    const inputValue = newValue || "";

    try {
      var autocompleteLib = autocomplete;
      if (!autocompleteLib && google) {
        autocompleteLib = new google.maps.places.AutocompleteService();
        setAutocomplete(autocompleteLib);
      }

      if (autocompleteLib && inputValue !== "") {
        const results = await autocompleteLib.getPlacePredictions({
          input: inputValue,
          types: ["address"],
          language: "en",
        });
        setOptions(
          results.predictions.map((prediction) => prediction.description)
        );
        console.log(results);
      }
    } catch (error) {
      console.error("Error retrieving address suggestions:", error);
    }

    onChange(inputValue);
  };

  return (
    <Autocomplete
      style={style}
      options={options}
      getOptionLabel={(option) => option}
      onInputChange={handleAddressChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Address"
          value={value}
          required={required}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    />
  );
};

export default AddressAutocomplete;
