import { StyleSheet } from 'react-native'
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox } from '@mui/material'
import TimeSelect from './TimeSelect'

const styles = StyleSheet.create({
  container: {
    marginTop: 20
  }
})

const ScheduleSelector = (props) => {
  const onValueChange = (item) => {
    props.schedule[item.value] = item
    props.onValueChange(props.schedule)
  }

  return (
    <div style={styles.container}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Availability</TableCell>
              <TableCell align='right'>Available?</TableCell>
              <TableCell align='right'>Start Time</TableCell>
              <TableCell align='right'>End Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(props.schedule).map((item) => (
              <TableRow key={item.name}>
                <TableCell>{item.name}</TableCell>
                <TableCell align='right'>
                  <Checkbox
                    checked={item.available}
                    onChange={(event) => {
                      item.available = event.target.checked
                      onValueChange(item)
                    }}
                />
                </TableCell>
                <TableCell align='right'>
                  <TimeSelect
                    onValueChange={(event) => {
                      item.startTime = event.target.value
                      onValueChange(item)
                    }}
                    value={item.startTime} />
                </TableCell>
                <TableCell align='right' key={item.endTime}>
                  <TimeSelect
                    onValueChange={(event) => {
                      item.endTime = event.target.value
                      onValueChange(item)
                    }}
                    value={item.endTime} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>)
}

export default ScheduleSelector
