import { useContext, useState } from "react";
import Profile from "./Profile";
import AuthContext from "./AuthContext";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  ListItem,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  SwipeableDrawer,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, useParams } from "react-router-dom";
import AccountBoxIcon from "@mui/icons-material/AccountBoxOutlined";
import CalendarTodayIcon from "@mui/icons-material/CalendarTodayOutlined";
import EventIcon from "@mui/icons-material/EventOutlined";
import LogoutIcon from "@mui/icons-material/LogoutOutlined";
import LoadingScreen from "./LoadingScreen";
import EventScreen from "./EventsScreen";
import CalendarScreen from "./CalendarsScreen";
import Logo from "./img/logo.svg";
import LogoDarkMode from "./img/logo_dark_mode.svg";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructionsOutlined";

const DashboardScreen = (props) => {
  const { current } = useParams();
  const { loggedIn, user, token, signOut, loaded, theme, setThemeMode, mode } =
    useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState("left");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const standalone =
    window.navigator &&
    "standalone" in window.navigator &&
    window.navigator.standalone;
  if (loaded && !loggedIn) {
    return navigate("/");
  }
  if (!loggedIn) {
    return <LoadingScreen open />;
  }

  const signOutAction = () => {
    signOut();
    navigate("/");
  };

  const handleClose = (screenName, event) => {
    setAnchorEl("left");
    setDrawerOpen(false);
    navigate("/dashboard/" + screenName);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  return (
    <Box>
      {standalone && <div style={{ marginTop: 60 }}></div>}
      <AppBar position="static" elevation={0}>
        <Toolbar>
          <IconButton
            onClick={toggleDrawer(anchorEl, true)}
            edge="start"
            color="inherit"
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <SwipeableDrawer
            anchor={anchorEl}
            open={drawerOpen}
            onClose={toggleDrawer(anchorEl, false)}
            onOpen={toggleDrawer(anchorEl, true)}
          >
            {standalone && <div style={{ marginTop: 60 }}></div>}
            <List>
              <ListItem onClick={(event) => handleClose("profile", event)}>
                <ListItemButton>
                  <ListItemIcon>
                    <AccountBoxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Profile" />
                </ListItemButton>
              </ListItem>
              <ListItem onClick={(event) => handleClose("calendars", event)}>
                <ListItemButton>
                  <ListItemIcon>
                    <IntegrationInstructionsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Integrations" />
                </ListItemButton>
              </ListItem>
              <ListItem onClick={(event) => handleClose("events", event)}>
                <ListItemButton>
                  <ListItemIcon>
                    <EventIcon />
                  </ListItemIcon>
                  <ListItemText primary="Events" />
                </ListItemButton>
              </ListItem>
              <ListItem
                onClick={() => window.open("https://discord.gg/m87m4Xrg")}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <SupportAgentIcon />
                  </ListItemIcon>
                  <ListItemText primary="Support" />
                </ListItemButton>
              </ListItem>
              <ListItem onClick={signOutAction}>
                <ListItemButton>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sign Out" />
                </ListItemButton>
              </ListItem>
            </List>
          </SwipeableDrawer>
          {mode === "light" && (
            <Box
              component="img"
              src={Logo}
              alt="Meet quick logo"
              style={{ height: 54, margin: 10 }}
            />
          )}
          {mode === "dark" && (
            <Box
              component="img"
              src={LogoDarkMode}
              alt="Meet quick logo"
              style={{ height: 54, margin: 10 }}
            />
          )}
          <Typography style={{ flexGrow: 1 }} />
          <IconButton
            sx={{ ml: 1, marginRight: 1 }}
            onClick={() => setThemeMode(mode === "light" ? "dark" : "light")}
            color="inherit"
          >
            {theme.palette.mode === "dark" ? (
              <Brightness7Icon />
            ) : (
              <Brightness4Icon />
            )}
          </IconButton>
          <Avatar alt={user.name} src={user.picture} />
        </Toolbar>
      </AppBar>
      {current === "profile" && <Profile token={token} user={user} />}
      {current === "events" && <EventScreen token={token} user={user} />}
      {current === "calendars" && <CalendarScreen token={token} user={user} />}
    </Box>
  );
};

export default DashboardScreen;
