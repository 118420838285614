import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  Grid,
  FormControl,
  InputLabel,
  Container,
  CircularProgress,
  Alert,
  Avatar,
  OutlinedInput,
} from "@mui/material";
import MeetingLength from "./MeetingLength";
import ScheduleSelector from "./ScheduleSelector";
import BufferTimeSelect from "./BufferTimeSelect";
import MeetingTypeSelect from "./MeetingTypeSelect";
import MeetingAccountSelect from "./MeetingAccountSelect";
import FutureBookingSelect from "./FutureBookingSelect";
import EditIcon from "@mui/icons-material/EditOutlined";
import SaveIcon from "@mui/icons-material/SaveOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { SketchPicker } from "react-color";
import { isEmpty } from "lodash";
import { url, defaultEventSettings } from "./constants";
import LoadingScreen from "./LoadingScreen";
import AuthContext from "./AuthContext";
import MeetingPaymentTypeSelect from "./MeetingPaymentTypeSelect";
const axios = require("axios").default;

const EventEditScreen = (props) => {
  const { state } = useLocation();
  const { event } = state || { event: defaultEventSettings };
  const { user, token } = useContext(AuthContext);
  const { id } = useParams();
  const [eventData, setEvent] = useState(event);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [accountsLoaded, setAccountsLoaded] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(true);

  const saveEvent = () => {
    setLoading(true);
    axios
      .post(
        "https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/events/save",
        { token: props.token, event: eventData }
      )
      .then((res) => {
        setLoading(false);
        cancel();
      });
  };

  const createEvent = () => {
    setLoading(true);
    axios
      .post(
        "https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/events/create",
        { token: props.token, event: eventData }
      )
      .then((res) => {
        setLoading(false);
        cancel();
      });
  };

  const getAccounts = async () => {
    if (!token.provider) {
      return;
    }
    return axios
      .post(
        "https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/calendars/list",
        { token: token }
      )
      .then((res) => {
        if (res.data !== "") {
          setAccounts([...res.data.calendars, ...res.data.integrations]);
        }
        setAccountsLoaded(true);
        return res;
      });
  };

  const getEvent = async () => {
    if (!eventData.id || !token.provider) {
      return;
    }
    return axios
      .post(
        "https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/event",
        { token: token, id: id }
      )
      .then((res) => {
        if (res.data !== "") {
          setEvent(res.data);
        }
        return res;
      });
  };

  const getMeetingAccount = (e) => {
    if (accountsLoaded && e.meetingType !== "phone" && e.meetingType) {
      const validAccounts = [];
      accounts.forEach((account) => {
        if (
          account.provider === "Microsoft" &&
          (e.meetingType === "skype" ||
            e.meetingType === "teams" ||
            e.meetingType === "skype_business")
        ) {
          validAccounts.push(account);
        } else if (
          account.provider === "Google" &&
          e.meetingType === "google_meet"
        ) {
          validAccounts.push(account);
        } else if (
          account.provider === "Stripe" &&
          e.meetingType === "service"
        ) {
          validAccounts.push(account);
        }
      });
      if (isEmpty(validAccounts)) {
        return (
          <Grid item xs={12}>
            <FormControl style={{ marginTop: 30 }}>
              <Alert severity="error">
                No valid account for this meeting type. Please visit the
                integrations page to setup integration.
              </Alert>
            </FormControl>
          </Grid>
        );
      }
      return (
        <Grid item xs={12}>
          <FormControl className="meetquick-input" style={{ marginTop: 30 }}>
            <InputLabel id="meeting-account-label-id">
              Meeting Account
            </InputLabel>
            <MeetingAccountSelect
              accounts={validAccounts}
              label="Meeting Account"
              labelId="meeting-account-label-id"
              value={eventData.meetingAccount}
              onValueChange={(event) => {
                setEvent({
                  ...eventData,
                  meetingAccount: event.target.value,
                });
              }}
            />
          </FormControl>
        </Grid>
      );
    }
  };

  const handleAmountChange = (event) => {
    const inputValue = event.target.value;

    // Convert the amount to cents
    if (inputValue !== "") {
      const inputValueInCents = Math.floor(inputValue * 100);
      return inputValueInCents;
    } else {
      return 0;
    }
  };

  const submit = (event) => {
    event.preventDefault();
    createEvent();
  };

  const cancel = () => {
    navigate("/dashboard/events/");
  };

  useEffect(() => {
    Promise.all([getAccounts(), getEvent()]).then((res) => {
      setLoadingScreen(false);
    });
  }, [token, user]);

  if (loadingScreen) {
    return <LoadingScreen open={loadingScreen} />;
  }

  return (
    <Box>
      <Container>
        {props.create && (
          <Typography variant="h6" style={{ marginTop: 15, marginBottom: 15 }}>
            Create event details
          </Typography>
        )}
        {!props.create && (
          <Typography variant="h6" style={{ marginTop: 15, marginBottom: 15 }}>
            Update event details
          </Typography>
        )}
        <form onSubmit={submit} autoComplete="off">
          <Grid container>
            <Grid item xs={12}>
              <InputLabel id="event-color-label-id">Event Color</InputLabel>
              <Avatar
                style={{ marginTop: 5, marginLeft: 2 }}
                sx={{ bgcolor: eventData.color }}
                onClick={() => setDisplayColorPicker(true)}
              >
                <EditIcon />
              </Avatar>
              {displayColorPicker && (
                <div style={{ position: "absolute", zIndex: "2" }}>
                  <div
                    style={{
                      position: "fixed",
                      top: "0px",
                      right: "0px",
                      bottom: "0px",
                      left: "0px",
                    }}
                    onClick={() => setDisplayColorPicker(false)}
                  />
                  <SketchPicker
                    color={eventData.color}
                    onChangeComplete={(color) => {
                      setEvent({
                        ...eventData,
                        color: color.hex,
                      });
                      setDisplayColorPicker(false);
                    }}
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="meetquick-input"
                label="Title"
                required
                onChange={(val) =>
                  setEvent({
                    ...eventData,
                    title: val.target.value,
                  })
                }
                style={{ marginTop: 25 }}
                value={eventData.title}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Link"
                className="meetquick-input"
                required
                onChange={(val) =>
                  setEvent({
                    ...eventData,
                    link: val.target.value.replace(/[^a-z0-9-]/g, ""),
                  })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{`${url}/${props.user.username}/`}</InputAdornment>
                  ),
                }}
                style={{ marginTop: 30 }}
                value={eventData.link}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                className="meetquick-input"
                required
                multiline
                minRows={3}
                onChange={(val) =>
                  setEvent({
                    ...eventData,
                    description: val.target.value,
                  })
                }
                style={{ marginTop: 30 }}
                value={eventData.description}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="meetquick-input"
                label="Redirect URL"
                required
                onChange={(val) =>
                  setEvent({
                    ...eventData,
                    redirectUrl: val.target.value,
                  })
                }
                style={{ marginTop: 30 }}
                value={eventData.redirectUrl}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className="meetquick-input"
                style={{ marginTop: 30 }}
              >
                <InputLabel id="event-length-label-id">
                  Meeting Length
                </InputLabel>
                <MeetingLength
                  label="Meeting Length"
                  labelId="event-length-label-id"
                  value={eventData.meetingLength}
                  onValueChange={(event) => {
                    setEvent({
                      ...eventData,
                      meetingLength: parseInt(event.target.value),
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className="meetquick-input"
                style={{ marginTop: 30 }}
              >
                <InputLabel id="event-buffer-label-id">
                  Meetings Can Be Scheduled Within
                </InputLabel>
                <BufferTimeSelect
                  label="Meetings Can Be Scheduled Within"
                  labelId="event-buffer-label-id"
                  value={eventData.meetingBuffer}
                  onValueChange={(event) => {
                    console.log(
                      "meeting buffer changed",
                      event.target.value,
                      parseInt(event.target.value)
                    );
                    setEvent({
                      ...eventData,
                      meetingBuffer: parseInt(event.target.value),
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className="meetquick-input"
                style={{ marginTop: 30 }}
              >
                <InputLabel id="event-future-booking-label-id">
                  Meetings Cannot Be Scheduled After
                </InputLabel>
                <FutureBookingSelect
                  label="Meetings Cannot Be Scheduled After"
                  labelId="event-future-booking-label-id"
                  value={eventData.meetingFutureBooking}
                  onValueChange={(event) => {
                    setEvent({
                      ...eventData,
                      meetingFutureBooking: parseInt(event.target.value),
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className="meetquick-input"
                style={{ marginTop: 30 }}
              >
                <InputLabel id="meeting-type-label-id">Meeting Type</InputLabel>
                <MeetingTypeSelect
                  label="Meeting Type"
                  labelId="meeting-type-label-id"
                  value={eventData.meetingType}
                  onValueChange={(event) => {
                    setEvent({
                      ...eventData,
                      meetingType: event.target.value,
                    });
                  }}
                />
              </FormControl>
            </Grid>
            {getMeetingAccount(eventData)}
            {eventData.meetingType === "service" && (
              <Grid item xs={12}>
                <FormControl
                  className="meetquick-input"
                  style={{ marginTop: 30 }}
                >
                  <InputLabel id="payment-type-label-id">
                    Payment Type
                  </InputLabel>
                  <MeetingPaymentTypeSelect
                    label="Payment Type"
                    labelId="payment-type-label-id"
                    value={eventData.paymentType}
                    onValueChange={(event) => {
                      setEvent({
                        ...eventData,
                        paymentType: event.target.value,
                      });
                    }}
                  />
                </FormControl>
              </Grid>
            )}
            {eventData.meetingType === "service" &&
              eventData.paymentType !== "none" && (
                <Grid item xs={12}>
                  <FormControl
                    className="meetquick-input"
                    style={{ marginTop: 30 }}
                  >
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Price
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      type="number"
                      label="Price"
                      step="0.01"
                      min="0"
                      onChange={(val) =>
                        setEvent({
                          ...eventData,
                          paymentAmount: val.target.value,
                          paymentAmountCents: handleAmountChange(val),
                        })
                      }
                      value={eventData.paymentAmount}
                    />
                  </FormControl>
                </Grid>
              )}
            {eventData.meetingType === "service" &&
              eventData.paymentType === "calculation" && (
                <Grid item xs={12}>
                  <TextField
                    className="meetquick-input"
                    label="Price Per Label"
                    placeholder="Hour"
                    required
                    onChange={(val) =>
                      setEvent({
                        ...eventData,
                        amountPerLabel: val.target.value,
                      })
                    }
                    style={{ marginTop: 25 }}
                    value={eventData.amountPerLabel}
                  />
                </Grid>
              )}
            <Grid item xs={12}>
              <div style={{ marginTop: 30 }}>
                <ScheduleSelector
                  schedule={eventData.schedule}
                  onValueChange={(val) => {
                    setEvent({
                      ...eventData,
                      schedule: val,
                    });
                  }}
                />
              </div>
            </Grid>
          </Grid>
          {props.create && (
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{ marginTop: 15, marginBottom: 15 }}
            >
              {loading && (
                <CircularProgress
                  style={{ marginRight: 10 }}
                  color="inherit"
                  size={20}
                />
              )}
              <AddCircleOutlineIcon style={{ marginRight: 5 }} /> Create Event
            </Button>
          )}
          {!props.create && (
            <Button
              variant="outlined"
              color="primary"
              onClick={saveEvent}
              style={{ marginTop: 15, marginBottom: 15 }}
            >
              {loading && (
                <CircularProgress
                  style={{ marginRight: 10 }}
                  color="inherit"
                  size={20}
                />
              )}
              <SaveIcon style={{ marginRight: 5 }} /> Save
            </Button>
          )}
          <Button
            variant="outlined"
            color="secondary"
            onClick={cancel}
            style={{ marginTop: 15, marginBottom: 15, marginLeft: 15 }}
          >
            Cancel
          </Button>
        </form>
      </Container>
    </Box>
  );
};

export default EventEditScreen;
