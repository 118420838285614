import { useState, useContext } from 'react'
import GoogleButton from './GoogleButton'
import { useNavigate } from 'react-router-dom'
import { Box, Container, Card, CardContent, Typography, Grid, AppBar, Toolbar, CircularProgress } from '@mui/material'
import AuthContext from './AuthContext'
import Logo from './img/logo.svg'
import LogoDarkMode from './img/logo_dark_mode.svg'

const LoginScreen = () => {
  const [loading, setLoading] = useState(false)
  const { mode } = useContext(AuthContext)
  const navigate = useNavigate()
  const setUser = (user) => {
    navigate('/dashboard/events')
  }

  return (
    <Box>
      <AppBar elevation={0}>
        <Toolbar>
          { mode === 'light' &&
          <Box
            component='img'
            src={Logo}
            alt='Meet quick logo'
            style={{ height: 54, margin: 10 }}
              />
          }
          { mode === 'dark' &&
            <Box
              component='img'
              src={LogoDarkMode}
              alt='Meet quick logo'
              style={{ height: 54, margin: 10 }}
                />
          }
          <Typography style={{ flexGrow: 1 }} />
        </Toolbar>
      </AppBar>
      <Container>
        <Grid container justifyContent='center' alignItems='center' style={{minHeight: '100vh'}}>
          <Grid item>
            <Card>
              <CardContent>
                <Typography gutterBottom variant='h5' component='h3'>
                  Sign In
                  {loading && <CircularProgress style={{marginLeft: 10, float: 'right'}} color='inherit' size={20} />}
                </Typography>
                <Typography gutterBottom variant='body2' color='textSecondary' component='p'>
                  Authorize with Google account to get started
                </Typography>
                <GoogleButton login setLoading={setLoading} setUser={setUser} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>)
}

export default LoginScreen
