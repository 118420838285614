import { useContext } from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import AuthContext from './AuthContext'
import { Button } from '@mui/material'
import { defaultSettings } from './constants'
import GoogleLogo from './img/g-logo.svg'
const axios = require('axios').default

const GoogleButton = (props) => {
  const { signIn } = useContext(AuthContext)

  const login = useGoogleLogin({
    ux_mode: 'popup',
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/calendar',
    onSuccess: tokenResponse => {
      if (props.login) {
        axios.post('https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/google/auth', {token: tokenResponse, settings: defaultSettings})
        .then(res => {
          res.data.user.username = res.data.username
          signIn(res.data.token, res.data.user)
          props.setUser(res)
          props.setLoading(false)
        }).catch(err => {
          console.log(err)
          props.setLoading(false)
        })
      } else {
        axios.post('https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/google/connect', {
          token: tokenResponse, auth: props.token, settings: defaultSettings})
        .then(res => {
          props.setUser(res)
          props.setLoading(false)
        }).catch(err => {
          console.log(err)
          props.setLoading(false)
        })
      }
    },
    onError: errorResponse => {
      console.log(errorResponse)
      props.setLoading(false)
    }
  })

  return (
    <div style={{marginTop: 10}}>
      <Button style={{backgroundColor: 'rgb(255, 255, 255)', height: 55, width: '100%', display: 'block', borderRadius: 5, border: 'thin solid #888', boxShadow: '1px 1px 1px grey'}}
        onClick={e => {
          props.setLoading(true)
          login(e)
        }}>
        <img style={{width: 21, height: 21, margin: 6, textAlign: 'center', display: 'block', float: 'left', whiteSpace: 'nowrap'}} src={GoogleLogo} />
        <p style={{color: 'rgba(0, 0, 0, 0.54)', margin: 4, fontSize: '15px', fontWeigth: 600, textTransform: 'none'}}>
          Sign in with Google
        </p>
      </Button>
    </div>
  )
}

export default GoogleButton
