import { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Button,
  Grid,
  Paper,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";
import LoadingScreen from "./LoadingScreen";
import AuthContext from "./AuthContext";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { randomColor, getMeetingTypeIcon } from "./utils";
import MeetingPaymentLabel from "./MeetingPaymentLabel";
const axios = require("axios").default;

const ScheduleEventsScreen = () => {
  const navigate = useNavigate();
  const { theme, setThemeMode, mode } = useContext(AuthContext);
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [events, setEvents] = useState([]);
  const [settings, setSettings] = useState({});
  const [user, setUser] = useState({});
  const { username, link } = useParams();

  const handleBookNow = (e) => {
    navigate(e.link);
  };

  const getEvents = () => {
    console.log("Getting events");
    setLoadingScreen(true);
    axios
      .get(
        "https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/available/events",
        {
          params: {
            username,
            link,
          },
        }
      )
      .then((res) => {
        setLoadingScreen(false);
        setEvents(res.data.events);
        setSettings(res.data.settings);
        setUser(res.data.user);
      });
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <Box>
      <LoadingScreen open={loadingScreen} />
      {!loadingScreen && (
        <Container>
          <Grid container>
            <Grid xs={11} item>
              <Typography
                variant="h5"
                style={{
                  marginTop: 30,
                  marginLeft: 15,
                  flexDirection: "row",
                  alignItems: "center",
                  display: "flex",
                }}
                gutterBottom
              >
                <Avatar
                  alt={user.name}
                  style={{ marginRight: 8 }}
                  src={user.picture}
                />{" "}
                {settings.eventPageTitle}
              </Typography>
              <Typography
                variant="body1"
                style={{ marginTop: 10, marginLeft: 15 }}
                gutterBottom
              >
                {settings.eventPageDescription}
              </Typography>
            </Grid>
            <Grid xs={1} item>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                align="right"
                style={{ marginTop: 30 }}
              >
                <IconButton
                  onClick={() =>
                    setThemeMode(mode === "light" ? "dark" : "light")
                  }
                  color="inherit"
                >
                  {theme.palette.mode === "dark" ? (
                    <Brightness7Icon />
                  ) : (
                    <Brightness4Icon />
                  )}
                </IconButton>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: 15 }}>
            {events.map((e, i) => (
              <Grid item xs={12} sm={6} key={e.id}>
                <Paper elevation={2}>
                  <Grid container>
                    <Grid xs={12} item>
                      <div
                        style={{
                          marginLeft: 15,
                          marginTop: 15,
                          display: "inline-block",
                          borderRadius: "50%",
                          width: 12,
                          height: 12,
                          backgroundColor: e.color || randomColor(),
                        }}
                      />
                      <div
                        style={{
                          paddingTop: 10,
                          paddingRight: 10,
                          float: "right",
                        }}
                      >
                        {getMeetingTypeIcon(e.meetingType)}
                      </div>
                      <Typography
                        variant="h6"
                        style={{ paddingTop: 5, marginLeft: 15 }}
                      >
                        {e.title}
                      </Typography>
                    </Grid>
                    <Grid xs={12} item>
                      <Typography
                        style={{
                          marginTop: 15,
                          marginBottom: 15,
                          marginLeft: 15,
                          marginRight: 15,
                          minHeight: 120,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: "5",
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {e.description}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid xs={12} item>
                    <Button
                      style={{
                        marginTop: 15,
                        marginBottom: 15,
                        marginLeft: 15,
                      }}
                      variant="outlined"
                      color="primary"
                      onClick={() => handleBookNow(e)}
                    >
                      Book now
                    </Button>
                    <MeetingPaymentLabel
                      style={{
                        float: "right",
                        marginTop: 25,
                        marginLeft: 15,
                        marginRight: 15,
                      }}
                      e={e}
                    />
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </Box>
  );
};

export default ScheduleEventsScreen;
