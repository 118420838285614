import { createTheme } from '@mui/material/styles'

export const getTheme = (mode, primary, secondary) => createTheme({
  palette: {
    mode: mode,
    primary: {
      main: primary
    },
    secondary: {
      main: secondary
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: mode === 'dark' ? 'transparent' : 'white'
        }
      }
    }
  }
})
