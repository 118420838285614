import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Paper,
  Divider,
  FormGroup,
  FormControlLabel,
  Switch,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Checkbox,
  FormControl,
  InputLabel,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogSubTitle,
  DialogContentText,
  DialogActions,
  TextField,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
} from "@mui/material";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructionsOutlined";
import LoadingScreen from "./LoadingScreen";
import WriteableSelector from "./WriteableSelector";
import GoogleLogo from "./img/g-logo.svg";
import MsLogo from "./img/ms_logo.svg";
import StripeLogo from "./img/stripe.svg";
import HubSpotLogo from "./img/hubspot.png";
import CalendarTodayIcon from "@mui/icons-material/CalendarTodayOutlined";
import SaveIcon from "@mui/icons-material/SaveOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
const axios = require("axios").default;

const CalendarsScreen = (props) => {
  const navigate = useNavigate();
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [calendars, setCalendars] = useState([]);
  const [writeables, setWriteables] = useState({});
  const [integrations, setIntegrations] = useState([]);
  const [writeable, setWriteable] = useState({ label: "" });
  const [loadingSave, setLoadingSave] = useState({});
  const [loadingDelete, setLoadingDelete] = useState({});
  const [loadingEnable, setLoadingEnable] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [stripeName, setStripeName] = useState("");
  const [stripePubKey, setStripePubKey] = useState("");
  const [stripeSecretKey, setStripeSecretKey] = useState("");
  const [hubSpotDialogOpen, setHubSpotDialogOpen] = useState(false);
  const [hubSpotAccessToken, setHubSpotAccessToken] = useState("");
  const [hubSpotName, setHubSpotName] = useState("");

  const clickCreateNewCalendar = () => {
    console.log("Clicked create new calendar");
    navigate("connect");
  };

  const addNewPaymentAcount = () => {
    console.log("Adding new payment account");
    setDialogOpen(true);
  };

  const addHubSpotAccount = () => {
    console.log("Adding hubspot account");
    setHubSpotDialogOpen(true);
  };

  const handleEnableChange = (event, i) => {
    console.log("Enable changed", event.target.checked);
    const cals = calendars.map((cal, index) => {
      if (index === i) {
        return {
          ...cal,
          enabled: event.target.checked,
        };
      } else {
        return cal;
      }
    });
    setLoadingEnable({
      ...loadingEnable,
      [calendars[i].id]: true,
    });
    axios
      .post(
        "https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/calendars/save",
        { token: props.token, calendar: cals[i] }
      )
      .then((res) => {
        setLoadingEnable({
          ...loadingEnable,
          [calendars[i].id]: false,
        });
        setCalendars(cals);
      });
  };

  const handleSaveClick = (event, i) => {
    setLoadingSave({
      ...loadingSave,
      [calendars[i].id]: true,
    });
    axios
      .post(
        "https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/calendars/save",
        { token: props.token, calendar: calendars[i] }
      )
      .then((res) => {
        setLoadingSave({
          ...loadingSave,
          [calendars[i].id]: false,
        });
      });
  };

  const handleDeleteClick = (calendar, i) => {
    console.log("Handle delete clicked");
    setLoadingDelete({
      ...loadingDelete,
      [calendar.id]: true,
    });
    const cals = calendars.filter((cal) => {
      return cal.id !== calendar.id;
    });
    axios
      .post(
        "https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/calendars/delete",
        { token: props.token, id: calendar.id }
      )
      .then((res) => {
        setLoadingDelete({
          ...loadingDelete,
          [calendar.id]: false,
        });
        setCalendars(cals);
      });
  };

  const getCalendars = () => {
    setLoadingScreen(true);
    axios
      .post(
        "https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/calendars/list",
        { token: props.token }
      )
      .then((res) => {
        setLoadingScreen(false);
        if (res.data !== "") {
          setWriteables(getWritables(res.data.calendars));
          setWriteable(res.data.writeCalendar);
          setCalendars(res.data.calendars);
          setIntegrations(res.data.integrations);
        }
      });
  };

  const saveWriteable = (writeCalendar) => {
    setLoadingScreen(true);
    axios
      .post(
        "https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/calendars/write/save",
        { token: props.token, writeCalendar: writeCalendar }
      )
      .then((res) => {
        setLoadingScreen(false);
        setWriteable(writeCalendar);
      });
  };

  const getWritables = (data) => {
    const writeables = {};
    data.forEach((cal) => {
      cal.items.forEach((item) => {
        if (item.accessRole === "reader") return;
        const writeable = {
          label: `${cal.info.email} / ${item.summary}`,
          accountId: cal.id,
          calendarId: item.id,
          provider: cal.provider,
        };
        writeables[writeable.label] = writeable;
      });
    });
    return writeables;
  };

  const handleToggle = (e, item, i) => () => {
    if (e.selected.includes(item.id)) {
      e.selected = e.selected.filter((x) => x !== item.id);
    } else {
      e.selected.push(item.id);
    }
    const cals = calendars.map((cal, index) => {
      if (index === i) {
        return {
          ...cal,
          selected: e.selected,
        };
      } else {
        return cal;
      }
    });
    setCalendars(cals);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const hubSpotHandleClose = () => {
    setHubSpotDialogOpen(false);
  };

  const saveIntegration = (e) => {
    e.preventDefault();
    setDialogLoading(true);
    axios
      .post(
        "https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/integrations/create",
        {
          token: props.token,
          integration: {
            stripePubKey,
            stripeSecretKey,
            name: stripeName,
            provider: "Stripe",
          },
        }
      )
      .then((res) => {
        setStripeName("");
        setStripeSecretKey("");
        setStripePubKey("");
        setDialogLoading(false);
        setDialogOpen(false);
        getCalendars();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveHubSpot = (e) => {
    e.preventDefault();
    setDialogLoading(true);
    axios
      .post(
        "https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/integrations/create",
        {
          token: props.token,
          integration: {
            accessToken: hubSpotAccessToken,
            name: hubSpotName,
            provider: "HubSpot",
          },
        }
      )
      .then((res) => {
        setHubSpotAccessToken("");
        setHubSpotName("");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setDialogLoading(false);
        setHubSpotDialogOpen(false);
        getCalendars();
      });
  };

  const deleteIntegration = (id) => {
    setLoadingScreen(true);
    axios
      .post(
        "https://hlpscbffug.execute-api.us-west-2.amazonaws.com/prod/integrations/delete",
        {
          token: props.token,
          id: id,
        }
      )
      .then((res) => {
        getCalendars();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCalendars();
  }, []);

  const getLogo = (provider) => {
    if (provider === "Google") {
      return (
        <img
          alt="google logo"
          src={GoogleLogo}
          style={{ marginRight: 15, width: 30, height: 30 }}
        />
      );
    } else {
      return (
        <img
          alt="ms logo"
          src={MsLogo}
          style={{ marginRight: 15, width: 30, height: 30 }}
        />
      );
    }
  };

  return (
    <Box>
      <LoadingScreen open={loadingScreen} />
      {!loadingScreen && (
        <Container style={{ marginBottom: 20 }}>
          <Typography
            variant="h5"
            style={{
              marginTop: 20,
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
            }}
            gutterBottom
          >
            <IntegrationInstructionsIcon style={{ marginRight: 10 }} />{" "}
            Integrations
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={clickCreateNewCalendar}
            style={{ marginTop: 15 }}
          >
            <AddCircleOutlineOutlinedIcon style={{ marginRight: 5 }} />
            Connect New Calendar
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={addNewPaymentAcount}
            style={{ marginTop: 15, marginLeft: 10 }}
          >
            <AddCircleOutlineOutlinedIcon style={{ marginRight: 5 }} />
            Add Payment Account
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={addHubSpotAccount}
            style={{ marginTop: 15, marginLeft: 10 }}
          >
            <AddCircleOutlineOutlinedIcon style={{ marginRight: 5 }} />
            Add HubSpot Account
          </Button>
          <Grid
            container
            spacing={3}
            style={{ marginTop: 15, marginBottom: 15 }}
          >
            {calendars.map((e, i) => (
              <Grid item xs={12} sm={6} key={e.id}>
                <Paper elevation={2}>
                  <Typography
                    variant="h6"
                    style={{ paddingTop: 15, marginLeft: 15 }}
                    gutterBottom
                  >
                    {e.info.email}
                  </Typography>
                  <Typography
                    variant="h7"
                    style={{
                      paddingTop: 15,
                      marginLeft: 15,
                      flexDirection: "row",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {getLogo(e.provider)} {e.provider}
                  </Typography>
                  <Divider style={{ marginTop: 15 }} />
                  <FormGroup style={{ marginTop: 15, marginLeft: 25 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          checked={e.enabled}
                          onChange={(event) => handleEnableChange(event, i)}
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      }
                      label="Enabled"
                    />
                    {loadingEnable[e.id] && (
                      <CircularProgress
                        style={{ margin: 10 }}
                        color="inherit"
                        size={20}
                      />
                    )}
                  </FormGroup>
                  <Divider style={{ marginTop: 15 }} />
                  <List>
                    {e.items.map((item, ii) => (
                      <ListItem key={item.id} disablePadding>
                        <ListItemButton onClick={handleToggle(e, item, i)}>
                          <ListItemIcon>
                            <Checkbox
                              disableRipple
                              style={{ color: item.backgroundColor }}
                              checked={e.selected.includes(item.id)}
                            />
                          </ListItemIcon>
                          <ListItemText primary={item.summary} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                  <Divider />
                  <Button
                    style={{ marginTop: 15, marginBottom: 15, marginLeft: 15 }}
                    variant="outlined"
                    color="primary"
                    onClick={(event) => handleSaveClick(e, i)}
                  >
                    {loadingSave[e.id] && (
                      <CircularProgress
                        style={{ marginRight: 10 }}
                        color="inherit"
                        size={20}
                      />
                    )}
                    <SaveIcon style={{ marginRight: 5 }} /> Save
                  </Button>
                  <Button
                    style={{ marginTop: 15, marginBottom: 15, marginLeft: 15 }}
                    variant="outlined"
                    color="secondary"
                    onClick={(event) => handleDeleteClick(e)}
                  >
                    {loadingDelete[e.id] && (
                      <CircularProgress
                        style={{ marginRight: 10 }}
                        color="inherit"
                        size={20}
                      />
                    )}
                    <DeleteIcon style={{ marginRight: 5 }} />
                    Delete
                  </Button>
                </Paper>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Typography
                variant="p"
                component="p"
                style={{ marginTop: 10 }}
                gutterBottom
              >
                Choose a calendar that new events should be written to.
              </Typography>
              <FormControl
                style={{ marginTop: 15, marginBottom: 15, maxWidth: "88vw" }}
              >
                <InputLabel id="writeables-label-id">Write Calendar</InputLabel>
                <WriteableSelector
                  writeables={writeables}
                  value={writeable}
                  onValueChange={(value) => {
                    saveWriteable(value);
                  }}
                  labelId="writeables-label-id"
                  label="Write Calendar"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography>Integration Accounts</Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {integrations.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>
                          {row.provider === "Stripe" && (
                            <img
                              alt="stripe logo"
                              src={StripeLogo}
                              style={{ width: 50, height: 50 }}
                            />
                          )}
                          {row.provider === "HubSpot" && (
                            <img
                              alt="hubspot logo"
                              src={HubSpotLogo}
                              style={{ width: 70 }}
                            />
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">
                          <Button onClick={() => deleteIntegration(row.id)}>
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Container>
      )}
      <Dialog open={hubSpotDialogOpen} keepMounted onClose={hubSpotHandleClose}>
        <DialogTitle>Add HubSpot Account</DialogTitle>
        <form onSubmit={saveHubSpot} autoComplete="off">
          <DialogContent>
            <Typography>
              - Create Private App in HubSpot and copy Access Token to connect
            </Typography>
            <Typography>
              - Account must have crm.objects.contacts.read,
              crm.objects.contacts.write access
            </Typography>
            <Typography>
              - New bookings will be created as contacts in HubSpot
            </Typography>
            <TextField
              required
              style={{ marginBottom: 15, marginTop: 15 }}
              fullWidth
              name="hubSpotName"
              label="HubSpot Account Name"
              value={hubSpotName}
              onChange={(event) => setHubSpotName(event.target.value)}
            />
            <TextField
              required
              style={{ marginBottom: 15 }}
              fullWidth
              name="accessToken"
              label="HubSpot Access Token"
              value={hubSpotAccessToken}
              onChange={(event) => setHubSpotAccessToken(event.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} type="button">
              Cancel
            </Button>
            <Button type="submit">
              {dialogLoading && (
                <CircularProgress
                  style={{ marginRight: 10 }}
                  color="inherit"
                  size={20}
                />
              )}
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog open={dialogOpen} keepMounted onClose={handleClose}>
        <DialogTitle>Add Stripe Payment Account</DialogTitle>
        <form onSubmit={saveIntegration} autoComplete="off">
          <DialogContent>
            <TextField
              required
              style={{ marginBottom: 15 }}
              fullWidth
              name="stripeName"
              label="Stripe Account Name"
              value={stripeName}
              onChange={(event) => setStripeName(event.target.value)}
            />
            <TextField
              required
              style={{ marginBottom: 15 }}
              fullWidth
              name="stripePubKey"
              label="Stripe Publishable Key"
              value={stripePubKey}
              onChange={(event) => setStripePubKey(event.target.value)}
            />
            <TextField
              required
              style={{ marginBottom: 15 }}
              fullWidth
              name="stripeSecretKey"
              label="Stripe Secret Key"
              value={stripeSecretKey}
              onChange={(event) => setStripeSecretKey(event.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} type="button">
              Cancel
            </Button>
            <Button onClick={saveIntegration} type="submit">
              {dialogLoading && (
                <CircularProgress
                  style={{ marginRight: 10 }}
                  color="inherit"
                  size={20}
                />
              )}
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default CalendarsScreen;
