import { Select, MenuItem, ListItemIcon, ListItemText } from "@mui/material";

import GoogleImg from "./img/g-logo.svg";
import MsImg from "./img/ms_logo.svg";

const WriteableSelector = (props) => {
  const pickerItems = Object.keys(props.writeables).map((key) => {
    const value = props.writeables[key];

    if (value.provider === "Google") {
      return (
        <MenuItem value={key} key={key}>
          <ListItemIcon>
            <img src={GoogleImg} style={{ height: 25, width: 25 }} />
            <ListItemText style={{ marginLeft: 15 }} primary={key} />
          </ListItemIcon>
        </MenuItem>
      );
    } else if (value.provider === "Microsoft") {
      return (
        <MenuItem value={key} key={key}>
          <ListItemIcon>
            <img src={MsImg} style={{ height: 25, width: 25 }} />
            <ListItemText style={{ marginLeft: 15 }} primary={key} />
          </ListItemIcon>
        </MenuItem>
      );
    } else {
      return (
        <MenuItem value={key} key={key}>
          {key}
        </MenuItem>
      );
    }
  });

  return (
    <Select
      label={props.label}
      labelId={props.labelId}
      value={props.value.label}
      onChange={(event) => {
        props.onValueChange(props.writeables[event.target.value]);
      }}
    >
      {pickerItems}
    </Select>
  );
};

export default WriteableSelector;
