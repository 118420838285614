export const defaultEventSettings = {
  title: "",
  link: "",
  description: "",
  meetingLength: 60,
  meetingBuffer: 120,
  meetingFutureBooking: 60,
  meetingType: "phone",
  meetingAccount: "",
  color: "#32a8a6",
  enabled: false,
  paymentType: "none",
  paymentAmount: 100,
  amountPerLabel: "Hour",
  schedule: {
    0: {
      name: "Sunday",
      value: 0,
      startTime: 900,
      endTime: 1700,
      available: false,
    },
    1: {
      name: "Monday",
      value: 1,
      startTime: 900,
      endTime: 1700,
      available: true,
    },
    2: {
      name: "Tuesday",
      value: 2,
      startTime: 900,
      endTime: 1700,
      available: true,
    },
    3: {
      name: "Wednesday",
      value: 3,
      startTime: 900,
      endTime: 1700,
      available: true,
    },
    4: {
      name: "Thursday",
      value: 4,
      startTime: 900,
      endTime: 1700,
      available: true,
    },
    5: {
      name: "Friday",
      value: 5,
      startTime: 900,
      endTime: 1700,
      available: true,
    },
    6: {
      name: "Saturday",
      value: 6,
      startTime: 900,
      endTime: 1700,
      available: false,
    },
  },
};

export const defaultSettings = {
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  eventPageDescription: "Book a call with me",
  eventPageTitle: "",
  yourName: "",
};

export const company = "MeetQuick";
export const url = "https://meetquick.app";

export const meetingOptions = {
  "Phone Call": "phone",
  "Google Meet": "google_meet",
  Skype: "skype",
  "Skype for Business": "skype_business",
  "Microsoft Teams": "teams",
  Service: "service",
};

export const meetingOptionsLabel = {
  phone: "Phone Call",
  google_meet: "Google Meet",
  skype: "Skype",
  teams: "Microsoft Teams",
  skype_business: "Skype for Business",
  service: "Service Appointment",
};
