import makeStyles from '@mui/styles/makeStyles'
import { Box, Typography, Button } from '@mui/material'
import { ParallaxBanner } from 'react-scroll-parallax'

const HeroSection = (props) => {
  const classes = useStyles()

  return (
    <section className={classes.root}>
      <ParallaxBanner layers={[{ image: props.imgSrc, speed: -20 }]} />
      <div className={classes.overlay}>
        <Box
          height='100%'
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          color='#fff'
        >
          <Typography variant='h2' component='h2' className={classes.title}>
            {props.title}
          </Typography>
          <Typography variant='h6' component='h6' className={classes.title}>
            {props.subtitle}
          </Typography>
          <div>
            <Button color='primary' variant='contained' className={classes.button} onClick={() => props.onSignupClick()}>
              Sign Up
            </Button>
            <Button color='secondary' variant='outlined' className={classes.button} onClick={() => props.onLoginClick()}>
              Login
            </Button>
          </div>
        </Box>
      </div>
    </section>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100vh',
    position: 'relative',
    display: 'flex',
    zIndex: 0
  },
  overlay: {
    opacity: 0.9,
    backgroundColor: 'rgb(255, 255, 255)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  title: {
    color: 'black',
    paddingBottom: theme.spacing(1),
    margin: 10
  },
  button: {
    margin: 10
  }
}))

export default HeroSection
