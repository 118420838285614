import { Select, MenuItem } from '@mui/material'

const timeOptions = {
  '7 Days': 7,
  '14 days': 14,
  '30 Days': 30,
  '60 Days': 60,
  '90 Days': 90,
  '120 Days': 120,
  '240 Days': 240,
  '365 Days': 365
}
const defaultDays = 60

const BufferTimeSelect = (props) => {
  const pickerItems = Object.keys(timeOptions).map(key => {
    const value = timeOptions[key]
    return <MenuItem value={value} key={key}>{key}</MenuItem>
  })

  return (
    <Select
      label={props.label}
      value={props.value || defaultDays}
      onChange={props.onValueChange}>
      {pickerItems}
    </Select>
  )
}

export default BufferTimeSelect
